<template>
  <BreadCrumb PageTitle="Listado de mantenimientos" Subtitle="Mantenimiento"></BreadCrumb>
  <Maintenance/>
</template>

<script>
import Maintenance from "@/components/Maintenance/Maintenance.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";

export default {
  name: "MaintenanceView",
  components: {
    Maintenance,
    BreadCrumb
  }
}
</script>

<style scoped>

</style>