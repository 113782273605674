<template>
  <BreadCrumb PageTitle="Listado de clientes" Subtitle="Clientes"></BreadCrumb>
  <ClienteList></ClienteList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import ClienteList from "@/components/Clientes/ClienteList.vue";

export default {
  name: "Clientes",
  components: {BreadCrumb, MarcaList, ClienteList}
}
</script>

<style scoped>

</style>
