<template>
  <BreadCrumb PageTitle="Listado de procedencias de la reserva" Subtitle="Administración"></BreadCrumb>
  <ProcedenciaList></ProcedenciaList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ProcedenciaList from "@/components/Nomencladores/ProcedenciaList.vue";

export default {
  name: "Ciudades",
  components: {BreadCrumb, ProcedenciaList}
}
</script>

<style scoped>

</style>
