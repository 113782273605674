<template>
  <BreadCrumb PageTitle="Listado de notificaciones" Subtitle="Notificaciones"/>
  <Notifications/>
</template>

<script>
import Notifications from "@/components/Notifications/Notifications.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";

export default {
  name: "NotificationsView",
  components: {
    BreadCrumb,
    Notifications
  }
}
</script>

<style scoped>

</style>