<template>
  <div class="Cargando">
    <div v-show="loading||Cargando" :class="['modal_cargando', { 'show': loading||Cargando }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar empresa"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva empresa
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              RCF
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tel&eacute;fono
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Correo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in empresaByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.rcf }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.telefono }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.correo }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar empresa" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar empresa" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="open_Details(item)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Ver detalles del cliente" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="openPhoto(item.id)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Archivos de la empresa" data-container="body" data-animation="true"
                        class="btn text-primary">
                  <i class="flaticon-document d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar dropoff" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva Empresa
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">RCF</label>
            <input :class="error_rcf?'is-invalid':''" v-model="rcf"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="error_nombre?'is-invalid':''" v-model="nombre"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Teléfono</label>
            <MazPhoneNumberInput
                v-model="telefono"
                no-radius
                v-model:country-code="codigo"
                show-code-on-list
                :preferred-countries="['MX', 'US', 'CU']"
                :translations="{
                  countrySelector: {
                    placeholder: 'Código de país',
                    error: 'Seleccione un país',
                    searchPlaceholder: 'Buscar un país',
                  },
                  phoneInput: {
                    placeholder: 'Número de teléfono',
                    example: 'Ejemplo:',
                  },
                }"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Correo</label>
            <input :class="error_correo?'is-invalid':''" v-model="correo"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Dirección</label>
            <textarea :class="error_direccion?'is-invalid':''" v-model="direccion"
                      class="form-control shadow-none rounded-0 text-black"></textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar empresa
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">RCF</label>
            <input :class="error_rcf?'is-invalid':''" v-model="rcf"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="error_nombre?'is-invalid':''" v-model="nombre"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Teléfono</label>
            <MazPhoneNumberInput
                v-model="telefono"
                no-radius
                v-model:country-code="codigo"
                show-code-on-list
                :preferred-countries="['MX', 'US', 'CU']"
                :translations="{
                  countrySelector: {
                    placeholder: 'Código de país',
                    error: 'Seleccione un país',
                    searchPlaceholder: 'Buscar un país',
                  },
                  phoneInput: {
                    placeholder: 'Número de teléfono',
                    example: 'Ejemplo:',
                  },
                }"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Correo</label>
            <input :class="error_correo?'is-invalid':''" v-model="correo"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Dirección</label>
            <textarea :class="error_direccion?'is-invalid':''" v-model="direccion"
                      class="form-control shadow-none rounded-0 text-black"></textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles de la empresa
            <i @click="close_Details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-1 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Nombre:</h6>
            <p>{{ item.nombre }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>RCF:</h6>
            <p>{{ item.rcf }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Telefono:</h6>
            <p>{{ item.telefono }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Correo:</h6>
            <p>{{ item.correo }}</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h6>Dirección:</h6>
            <p>{{ item.direccion }}</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 d-flex justify-content-start mb-3">

        </div>
        <div class="col-md-6 col-lg-6 d-flex justify-content-end mb-3">
          <button @click="close_Details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="Subir_foto text-dark">
    <div v-if="Photo_Modal" :class="['modal', { 'show': Photo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Archivos de la empresa
            <i @click="close_Photo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex justify-content-between align-items-end">
            <div>
              <label class="form-label ms-1">Seleccione un archivo</label>
              <input :class="file===null?'is-invalid':''" @change="onPhoto"
                     class="form-control shadow-none rounded-0 text-black" type="file">
            </div>
            <div>
              <label class="form-label ms-1">Escriba el nombre</label>
              <input :class="nombre===''?'is-invalid':''" v-model="nombre"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <button @click="Subir_Photo" class="btn btn-danger h-50" type="button">Guardar</button>
          </div>
        </div>
        <div class="row ps-3 pe-3">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex justify-content-between align-items-center"
               v-for="item in empresaFilesById" :key="item.id">
            <p>{{ item.nombre }}</p>
            <a data-bs-toggle="tooltip" @click="openPhoto(item.id)"
               data-bs-placement="top" target="_blank" :href="getImageUrl(item.file)"
               title="Descargar archivo" data-container="body" data-animation="true"
               class="btn text-primary">
              <i class="flaticon-download d-flex justify-content-center align-items-center"></i></a>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Debe seleccionar un archivo
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Photo" class="btn btn-secondary d-block w-100" type="button">Cerrar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazInput from 'maz-ui/components/MazInput'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
import {srac_url_image} from "@/actions/constants";
import {disableOffice, enableOffice, getOffice} from "@/actions";

const BUSCAR = gql`
  query EmpresaByName($name:String!) {
    empresaByName(name: $name) {
        id
        nombre
        direccion
        codigoTelefono
        telefono
        correo
        rcf
        isActive
    }
}`;
const NUEVO = gql`
mutation CreateEmpresa(
    $nombre:String!,
    $direccion:String!,
    $codigoTelefono:String!,
    $correo:String!,
    $telefono:String!,
    $rcf:String!
    ) {
    createEmpresa(
        obj: {
            nombre: $nombre
            direccion: $direccion
            codigoTelefono: $codigoTelefono
            correo: $correo
            telefono: $telefono
            rcf: $rcf
        }
    ) {
        success
        error
    }
}`;
const ACTUALIZAR = gql`
mutation UpdateEmpresa(
    $id:ID!,
    $nombre:String!,
    $direccion:String!,
    $codigoTelefono:String!,
    $correo:String!,
    $telefono:String!,
    $rcf:String!
    ) {
    updateEmpresa(
        obj: {
            id: $id
            nombre: $nombre
            direccion: $direccion
            codigoTelefono: $codigoTelefono
            correo: $correo
            telefono: $telefono
            rcf: $rcf
        }
    ) {
        success
        error
    }
}`;
const EMPRESAFILES = gql`
query EmpresaFilesById($id:ID!) {
    empresaFilesById(id: $id) {
        id
        file
        nombre
    }
}`;
const SUBIRFILE = gql`
mutation UploadFilesEmpresa(
    $id: ID!,
    $file: String!,
    $nombre: String!) {
    uploadFilesEmpresa(file: $file, nombre: $nombre, id: $id) {
        success
        error
    }
}`;
export default {
  name: "EmpresaList",
  components: {MazPhoneNumberInput, MazInput, VueGoogleAutocomplete},
  data() {
    return {
      empresaByName: [],
      empresaFilesById: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      Details_Modal: false,
      rcf: "",
      nombre: "",
      codigo: "",
      telefono: "",
      correo: "",
      direccion: "",
      error_rcf: false,
      error_nombre: false,
      error_codigo: false,
      error_telefono: false,
      error_correo: false,
      error_direccion: false,
      error_vacios: false,
      id: 0,
      item: null,
      center: {lat: 51.093048, lng: 6.842120},
      Photo_Modal: false,
      file: null,
      Cargando: false
    }
  },
  apollo: {
    empresaByName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: 'cache-and-network'
    }
  },
  watch: {
    rcf(value) {
      if (value !== "") {
        this.error_rcf = false
      }
    },
    nombre(value) {
      if (value !== "") {
        this.error_nombre = false
      }
    },
    codigo(value) {
      if (value !== "") {
        this.error_codigo = false
      }
    },
    telefono(value) {
      if (value !== "") {
        this.error_telefono = false
      }
    },
    correo(value) {
      if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
        this.error_correo = false
      }
    },
    direccion(value) {
      if (value !== "") {
        this.error_direccion = false
      }
    }
  },
  methods: {
    loaddata() {
      getOffice({name: this.search}).then(response => {
        this.empresaByName = response.data.data.empresaByName
      })
    },
    close_Details() {
      this.item = null
      this.Details_Modal = false
    },
    open_Details(item) {
      this.item = item
      this.Details_Modal = true
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.rcf = item.rcf
      this.nombre = item.nombre
      this.codigo = item.codigoTelefono
      this.telefono = item.telefono
      this.correo = item.correo
      this.direccion = item.direccion
      this.id = item.id
      this.Actualizar_Modal = true
    },
    Editar() {
      var validacion = true
      if (this.rcf === "" || /^[a-zA-Z0-9]*$/.test(this.rcf) === false || this.rcf.length !== 12) {
        this.error_rcf = true
        validacion = false
      }
      if (this.nombre === "") {
        this.error_nombre = true
        validacion = false
      }
      if (this.codigo === "") {
        this.error_codigo = true
        validacion = false
      }
      if (this.telefono === "") {
        this.error_telefono = true
        validacion = false
      }
      if (this.correo === "") {
        this.error_correo = true
        validacion = false
      }
      if (this.direccion === "") {
        this.error_direccion = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        this.$apollo.mutate({
          mutation: ACTUALIZAR,
          variables: {
            rcf: this.rcf,
            nombre: this.nombre,
            codigoTelefono: this.codigo,
            telefono: this.telefono,
            correo: this.correo,
            direccion: this.direccion,
            id: this.id
          },
          refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
        }).then(response => {
          if (response.data.updateEmpresa.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateEmpresa.error
            });
            this.Close_update()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.Close_update()
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    Close_nuevo() {
      this.rcf = ""
      this.nombre = ""
      this.codigo = ""
      this.telefono = ""
      this.correo = ""
      this.direccion = ""
      this.id = 0
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.rcf = ""
      this.nombre = ""
      this.codigo = ""
      this.telefono = ""
      this.correo = ""
      this.direccion = ""
      this.id = 0
      this.Actualizar_Modal = false
    },
    Crear() {
      var validacion = true
      if (this.rcf === "" || /^[a-zA-Z0-9]*$/.test(this.rcf) === false || this.rcf.length !== 12) {
        this.error_rcf = true
        validacion = false
      }
      if (this.nombre === "") {
        this.error_nombre = true
        validacion = false
      }
      if (this.codigo === "") {
        this.error_codigo = true
        validacion = false
      }
      if (this.telefono === "") {
        this.error_telefono = true
        validacion = false
      }
      if (this.correo === "" || /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.correo) === false) {
        this.error_correo = true
        validacion = false
      }
      if (this.direccion === "") {
        this.error_direccion = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        this.$apollo.mutate({
          mutation: NUEVO,
          variables: {
            rcf: this.rcf,
            nombre: this.nombre,
            codigoTelefono: this.codigo,
            telefono: this.telefono,
            correo: this.correo,
            direccion: this.direccion
          },
          refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
        }).then(response => {
          if (response.data.createEmpresa.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.createEmpresa.error
            });
            this.Close_nuevo()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.Close_nuevo()
        })
      } else {
        this.error_vacios = true
        return false
      }

    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La empresa estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enableOffice({id: id}).then(response => {
            if (response.data.data.activeEmpresa.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar la empresa"
              });
            }
            this.loaddata()
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La empresa no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disableOffice({id: id}).then(response => {
            if (response.data.data.desactiveEmpresa.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar la empresa"
              });
            }
            this.loaddata()
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.direccion = addressData.route + ", " + addressData.administrative_area_level_1
      console.log(this.direccion)
    },
    onPhoto(event) {
      const file = event.target.files[0];
      this.preview_imagen1 = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.file = reader.result
      }
      reader.readAsDataURL(file);
    },
    close_Photo() {
      this.Cargando = false
      this.file = null
      this.error_vacios = false
      this.id = 0
      this.nombre = ""
      this.preview = null
      this.Photo_Modal = false
    },
    openPhoto(item) {
      this.$apollo.query({
        query: EMPRESAFILES,
        variables: {
          id: item
        },
        fetchPolicy: "network-only"
      }).then(resp => {
        this.empresaFilesById = resp.data.empresaFilesById
      })
      this.id = item;
      this.Photo_Modal = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    Subir_Photo() {
      this.Cargando = true
      if (this.file === null) {
        this.error_vacios = true
        return false;
      }
      this.error_vacios = false
      this.$apollo.mutate({
        mutation: SUBIRFILE,
        variables: {
          id: this.id,
          nombre: this.nombre,
          file: this.file
        },
        refetchQueries: [{query: EMPRESAFILES, variables: {id: this.id}}]
      }).then(response => {
        if (response.data.uploadFilesEmpresa.success) {
          this.$apollo.query({
            query: EMPRESAFILES,
            variables: {
              id: this.id
            }
          }).then(resp => {
            this.empresaFilesById = resp.data.empresaFilesById
          })
          this.Cargando = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        } else {
          this.error_vacios = true
          this.Cargando = false
        }
      }).catch(
          this.Cargando = false
      )
    },
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
