<template>
  <div class="py-4 container-fluid bg-white text-dark">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="loadDatas(true)">
          <input type="text" v-model="search" class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Buscar notificación" @change="loadDatas" />
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button @click="() => newModal=true"
          class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none">
          Nueva notificaci&oacute;n
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0" v-if="!loading">
          <div v-if="notifications.length > 0" class="accordion" id="accordionExample">
            <ItemNotification @change-notification="()=>loadDatas()" @delete-notification="() => loadDatas()"
              v-for="notification in notifications" :key="notification.id" :notification="notification" />
          </div>
          <div v-else class="d-flex flex-column align-items-center">
            <EmptyNotifications size="330" />
          </div>

          <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
            <div class="row">
              <div class="col-lg-3 d-flex align-items-center justify-content-center bg-white text-dark">

              </div>
              <div class="col-lg-6 d-flex align-items-center justify-content-center bg-white text-dark">
                <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
                <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
                  <option :value=10>10</option>
                  <option :value=50>50</option>
                  <option :value=100>100</option>
                </select>
              </div>
              <div class="col-lg-3 d-flex align-items-center justify-content-center bg-white text-dark">
                <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage"
                  :disabled="hasPreviousPage===false"><i class="flaticon-chevron-1 "></i></button>
                <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
                    class="flaticon-chevron"></i></button>
              </div>
            </div>
          </div>
        </div>
        <LoadingDatas v-else />
      </div>
    </div>

    <div class="Nuevo text-dark">
      <div v-if="newModal" :class="['modal', { 'show': newModal }]">
        <div class="modal-content bg-white">
          <div class="row d-flex align-items-center">
            <h4 class="text-start fw-bold">
              Nueva notificaci&oacute;n
              <i @click="resetNew" class="flaticon-close opacity-10 modal-icon fs-15"></i>
            </h4>
          </div>
          <div class="row text-start">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="title">T&iacute;tulo</label>
              <input :disabled="saveChanges" :class="obj.title === ''? 'is-invalid' : ''" id="title" autofocus
                v-model="obj.title" class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="message">Descripci&oacute;n</label>
              <textarea rows="3" :disabled="saveChanges" :class="obj.message === '' ? 'is-invalid' : ''" id="message"
                v-model="obj.message" class="form-control shadow-none rounded-0 text-black"></textarea>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 pb-3 row">
              <div class="col-lg-10 col-md-9 col-sm-7">
                <MazSelect style="width: 100% !important" multiple :disabled="obj.allClients" v-model="obj.clients"
                  label="Clientes" :options="clients" search />
              </div>
              <div class="col-lg-2 col-md-3 col-sm-5 d-flex">
                <MazSwitch class="swtich-custom" v-model="obj.allClients" name="Todos">
                  Todos
                </MazSwitch>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="image">Imagen</label>
              <input :disabled="saveChanges" :class="obj.image === null ? 'is-invalid' : ''" id="image" @change="e => {
                  obj.image = e.target.files[0]
                }" class="form-control shadow-none rounded-0 text-black" type="file">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-start">
              <button :disabled="saveChanges" @click="resetNew" class="btn btn-secondary d-block w-100"
                type="button">Cancelar</button>
            </div>
            <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-end">
              <button :disabled="saveChanges || isNotValid" class="btn btn-danger d-block w-100" type="button"
                @click="createNotitificationCustomToUser">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyNotifications from "@/components/Notifications/EmptyNotifications.vue";
import LoadingDatas from "@/components/LoadingDatas.vue";
import MazSelect from 'maz-ui/components/MazSelect';
import MazSwitch from 'maz-ui/components/MazSwitch';
import {
  searchNextNotifications,
  searchNotifications,
  searchPrevNotifications,
  getAllClientsForNotifications,
  createCustomNotification
} from "@/actions";
import {decodeBase64} from "@/util";
import ItemNotification from "@/components/Notifications/ItemNotification.vue";
import Swal from "sweetalert2";

export default {
  name: "Notifications",
  components: {
    ItemNotification,
    EmptyNotifications,
    LoadingDatas,
    MazSelect,
    MazSwitch
  },
  data(){
    return{
      socket: new WebSocket(`${process.env.VUE_APP_URL_WS_NOTIFICATIONS}?user=${this.$store.state.userid}`),
      notifications: [],
      loading: true,
      search: "",

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,

      saveChanges: false,
      newModal: false,
      clients: [],

      obj: {
        title: "",
        message: "",
        clients: [],
        allClients: true,
        image: null,
      },

      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.socket.onmessage = (data)=>{
      const newNotification = JSON.parse(data.data.replaceAll("'", '"'));
      if(!this.notifications.find(e=>e.id==newNotification.id)){
        this.notifications = [
          {
            ...newNotification,
            readClient: false,
          },
          ...this.notifications,
        ];
      }
    };

    this.socket.onopen = (evt) =>{
      console.log(evt);
    }

    this.loadDatas(true);
    getAllClientsForNotifications().then(resp=>{
      this.clients = resp.data.data.clientesByName.map(e=>{
        return {
          value: e.id,
          label: `${e.nombre} ${e.apellidos}`
        }
      })
    }).catch(err=>console.log(err));
  },
  computed: {
    isNotValid(){
      return this.obj.title === '' || this.obj.message === '' || !this.obj.image ||
      (!this.obj.clients ||this. obj.clients.length === 0 && !this.obj.allClients);
    }
  },
  watch: {
    cant(value){
      this.loadDatas(true);
    }
  },
  methods:{
    createNotitificationCustomToUser(){
      this.saveChanges = true;
      createCustomNotification(this.obj).then(resp=>{
        const { error, ok } = resp.data.data.sendNotificationCustom;
        if(ok){
          this.resetNew();
          this.newModal = false;
          this.toast.fire({
            icon: "success",
            title: "Notificación enviada"
          });
          this.loadDatas(true);
        }
        if (error) {
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      })
    },
    resetNew(){
      this.obj = {
        title: "",
        message: "",
        clients: [],
        allClients: true,
        image: null,
      };
      this.newModal = false;
    },
    loadDatas(init = false) {
      this.loading = true;
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchNotifications({
        name: this.search,
        before: before,
        after: next,
        first: this.cant,
        id: this.$store.state.userid
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchFirebaseNotifications.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchFirebaseNotifications.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchFirebaseNotifications.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchFirebaseNotifications.pageInfo.endCursor;
        this.notifications = resp.data.data.searchFirebaseNotifications.edges.map(value => {
          value.node.idBase64 = value.node.id;
          value.node.id = decodeBase64(value.node.id);
          if (value.node.readadminSet.edges.length>0){
            value.node.read = value.node.readadminSet.edges[0].node.read;
            value.node.readId = value.node.readadminSet.edges[0].node.id;
          }else{
            value.node.read = true;
            value.node.readId = "";
          }
          return value.node;
        });
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      searchNextNotifications({
        name: this.search,
        after: this.endCursor,
        first: this.cant,
        id: this.$store.state.userid
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchFirebaseNotifications.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchFirebaseNotifications.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchFirebaseNotifications.pageInfo.endCursor;
        this.notifications = resp.data.data.searchFirebaseNotifications.edges.map(value => {
          value.node.idBase64 = value.node.id;
          value.node.id = decodeBase64(value.node.id);
          if (value.node.readadminSet.edges.length > 0) {
            value.node.read = value.node.readadminSet.edges[0].node.read;
            value.node.readId = value.node.readadminSet.edges[0].node.id;
          } else {
            value.node.read = true;
            value.node.readId = "";
          }
          return value.node;
        })
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevNotifications({
        name: this.search,
        before: this.startCursor,
        first: this.cant,
        id: this.$store.state.userid
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchFirebaseNotifications.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchFirebaseNotifications.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchFirebaseNotifications.pageInfo.endCursor;
        this.notifications = resp.data.data.searchFirebaseNotifications.edges.map(value => {
          value.node.idBase64 = value.node.id;
          value.node.id = decodeBase64(value.node.id);
          if (value.node.readadminSet.edges.length > 0) {
            value.node.read = value.node.readadminSet.edges[0].node.read;
            value.node.readId = value.node.readadminSet.edges[0].node.id;
          } else {
            value.node.read = true;
            value.node.readId = "";
          }
          return value.node;
        })
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>
    .class-btn-more{
      margin: 1rem auto;
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .modal-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  
    .modal-content {
      padding: 20px;
      width: 50%;
    }
  
    @media (max-width: 767px) {
      .modal-content {
        border-radius: 15px;
        padding: 10px;
        width: 90%;
      }
    }
  
    .modal {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .modal.show {
      opacity: 1;
    }

    .swtich-custom{
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
    }
</style>