<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar protección"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva protecci&oacute;n
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Descripci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tarifa
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in proteccionByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary descripcion-column">
              {{ item.descripcion }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.tarifa }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar protección" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar protección" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar protección" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="Details(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver detalles de la protección" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="Open_Protecciones(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Gestionar protecciones" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-settings d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva protecci&oacute;n
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input id="name_proteccion" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre en ingl&eacute;s</label>
            <input id="name_proteccion_en" v-model="nameEn"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <textarea id="descripcion_proteccion" v-model="descripcion"
                      class="form-control shadow-none rounded-0 text-black">
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n en ingl&eacute;s</label>
            <textarea id="descripcion_proteccion_en" v-model="descripcionEn"
                      class="form-control shadow-none rounded-0 text-black">
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa</label>
            <input id="tarifa_proteccion" v-model="tarifa" type="number"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar protecci&oacute;n
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input id="name_proteccion" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre en ingl&eacute;s</label>
            <input id="name_proteccion_en" v-model="nameEn"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <textarea id="descripcion_proteccion" v-model="descripcion"
                      class="form-control shadow-none rounded-0 text-black">
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n en ingl&eacute;s</label>
            <textarea id="descripcion_proteccion_en" v-model="descripcionEn"
                      class="form-control shadow-none rounded-0 text-black">
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa</label>
            <input id="tarifa_proteccion" v-model="tarifa" type="number"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles de la protecci&oacute;n
            <i @click="Close_details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="mb-3 d-flex justify-content-between">
          <h6 class="mb-2">Nombre en espa&ntilde;ol: {{ this.name }}</h6><br>
          <h6 class="mb-2">Nombre en ingl&eacute;s: {{ this.nameEn }}</h6><br>
          <h6>$ {{ this.tarifa }} MXN</h6>
        </div>
        <div class="row mb-3 text-start p-3">
          <p>Descripci&oacute;n en espa&ntilde;ol</p>
          <p class="mb-2">{{ this.descripcion }}</p>
          <p>Descripción en ingl&eacute;s</p>
          <p>{{ this.descripcionEn }}</p>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">

          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Close_details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Protecciones text-dark">
    <div v-if="Protecciones_Modal" :class="['modal', { 'show': Protecciones_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Gestionar protecciones de {{ this.name }}
            <i @click="Close_Protecciones" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start p-3">
          <div class="col-9">
            <p>Espa&ntilde;ol: <input type="text" class="form-control shadow-none rounded-0 text-black"
                                      v-model="proteccion"></p>
            <p>Ingl&eacute;s: <input type="text" class="form-control shadow-none rounded-0 text-black"
                                     v-model="proteccionEn"></p>
          </div>
          <div class="col-3 d-flex align-items-center">
            <button @click="AddProtecciones" v-if="operacion==='guardar'"
                    class="btn btn-danger d-block w-100" type="button">Guardar
            </button>
            <button @click="UpdateProtecciones" v-else
                    class="btn btn-danger d-block w-100" type="button">Guardar
            </button>
          </div>
        </div>
        <div class="row w-100 mb-3 p-3">
          <table class="table-responsive ">
            <tr>
              <th>Protecciones</th>
              <th>Acciones</th>
            </tr>
            <tr v-for="item in protecciones">
              <td class="text-start">{{ item.nombre }}</td>
              <td>
                <div class="btn-group" role="group">
                  <button v-if="item.isActive" data-bs-toggle="tooltip"
                          data-bs-placement="top" title="Desactivar protección" data-container="body"
                          data-animation="true" class="btn text-success" @click="DesactivarProtecciones(item.id)">
                    <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                  <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Activar protección" data-container="body" data-animation="true"
                          class="btn text-warning" @click="ActivarProtecciones(item.id)">
                    <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                  <button data-bs-toggle="tooltip" @click="CargarProteccion(item)"
                          data-bs-placement="top"
                          title="Editar protección" data-container="body" data-animation="true"
                          class="btn text-info">
                    <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="row p-3">
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Close_Protecciones" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  activeProtectionAction,
  addProtection,
  createProtection,
  desactiveProtectionAction,
  disableProtection,
  enableProtection,
  protectionsByIdAction,
  searchProtections,
  updateProtection,
  updateProtectionAction
} from "@/actions";

export default {
  name: "ProteccionList",
  data() {
    return {
      proteccionByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      name: '',
      nameEn: '',
      descripcion: '',
      descripcionEn: '',
      tarifa: 0,
      id: 0,
      id_proteccion: 0,
      error_vacios: false,
      Details_Modal: false,
      Protecciones_Modal: false,
      proteccion: "",
      proteccionEn: "",
      protecciones: [],
      proteccion_error: false,
      operacion: "guardar",
      loading: false,
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      searchProtections({
        name: this.search
      }).then(resp => {
        this.proteccionByName = resp.data.data.proteccionByName;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Open_Protecciones(item) {
      protectionsByIdAction({
        id: item.id
      }).then(response => {
        this.protecciones = response.data.data.proteccionesById;
      }).catch(err => console.log(err));
      this.name = item.name
      this.nameEn = item.nameEn
      this.id = item.id
      this.Protecciones_Modal = true
    },
    Close_Protecciones() {
      this.name = ""
      this.nameEn = ""
      this.proteccion = ""
      this.proteccionEn = ""
      this.protecciones = []
      this.Protecciones_Modal = false
    },
    AddProtecciones() {
      if (this.proteccion === "" || this.proteccionEn === "") {
        this.proteccion_error = true
        return false
      }
      addProtection({
        nombre: this.proteccion,
        nameEn: this.proteccionEn,
        proteccion: parseInt(this.id)
      }).then(response => {
        if (response.data.data.createProtecciones.success) {
          this.proteccion_error = false;
          protectionsByIdAction({
            id: this.id
          }).then(response => {
            this.protecciones = response.data.data.proteccionesById;
          }).catch(err => console.log(err));
          this.proteccion = ""
          this.proteccionEn = ""
        } else {
          this.proteccion_error = true
        }
      }).catch(err => console.log(err));
    },
    UpdateProtecciones() {
      if (this.proteccion === "" || this.proteccionEn === "") {
        this.proteccion_error = true
        return false
      }
      updateProtectionAction({
        id: parseInt(this.id_proteccion),
        nombre: this.proteccion,
        nameEn: this.proteccionEn,
        proteccion: parseInt(this.id)
      }).then(response => {
        if (response.data.data.updateProtecciones.success) {
          this.proteccion_error = false;
          protectionsByIdAction({
            id: this.id
          }).then(response => {
            this.protecciones = response.data.data.proteccionesById;
            this.operacion = "guardar"
            this.proteccion = ""
            this.proteccionEn = ""
          }).catch(err => console.log(err));
        } else {
          this.proteccion_error = true
        }
      }).catch(err => console.log(err));
    },
    CargarProteccion(item) {
      this.operacion = "actualizar"
      this.id_proteccion = item.id
      this.proteccion = item.nombre
      this.proteccionEn = item.nombreEn
    },
    Actualizar(item) {
      this.name = item.name
      this.nameEn = item.nameEn
      this.descripcion = item.descripcion
      this.descripcionEn = item.descripcionEn
      this.tarifa = item.tarifa
      this.id = parseInt(item.id)
      this.Actualizar_Modal = true
    },
    Details(item) {
      this.name = item.name
      this.nameEn = item.nameEn
      this.descripcion = item.descripcion
      this.descripcionEn = item.descripcionEn
      this.tarifa = item.tarifa
      this.Details_Modal = true
    },
    Editar() {
      let validacion = true
      if (this.name === '') {
        document.getElementById('name_proteccion').classList.add('is-invalid')
        validacion = false
      }
      if (this.nameEn === '') {
        document.getElementById('name_proteccion_en').classList.add('is-invalid')
        validacion = false
      }
      if (this.descripcion === '') {
        document.getElementById('descripcion_proteccion').classList.add('is-invalid')
        validacion = false
      }
      if (this.descripcionEn === '') {
        document.getElementById('descripcion_proteccion_en').classList.add('is-invalid')
        validacion = false
      }
      if (this.tarifa <= 0) {
        document.getElementById('tarifa_proteccion').classList.add('is-invalid')
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false;
        updateProtection({
          name: this.name,
          nameEn: this.nameEn,
          descripcion: this.descripcion,
          descripcionEn: this.descripcionEn,
          tarifa: this.tarifa,
          id: this.id
        }).then(response => {
          if (response.data.data.updateProteccion.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update();
            this.loadData();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.updateProteccion.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    Close_nuevo() {
      this.name = ''
      this.nameEn = ''
      this.descripcion = ''
      this.descripcionEn = ''
      this.tarifa = 0
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.id = 0;
      this.name = ''
      this.nameEn = ''
      this.descripcion = ''
      this.descripcionEn = ''
      this.tarifa = 0
      this.Actualizar_Modal = false
    },
    Close_details() {
      this.id = 0;
      this.name = ''
      this.nameEn = ''
      this.descripcion = ''
      this.descripcionEn = ''
      this.tarifa = 0
      this.Details_Modal = false
    },
    Crear() {
      let validacion = true
      if (this.name === '') {
        document.getElementById('name_proteccion').classList.add('is-invalid')
        validacion = false
      }
      if (this.nameEn === '') {
        document.getElementById('name_proteccion_en').classList.add('is-invalid')
        validacion = false
      }
      if (this.descripcion === '') {
        document.getElementById('descripcion_proteccion').classList.add('is-invalid')
        validacion = false
      }
      if (this.descripcionEn === '') {
        document.getElementById('descripcion_proteccion_en').classList.add('is-invalid')
        validacion = false
      }
      if (this.tarifa <= 0) {
        document.getElementById('tarifa_proteccion').classList.add('is-invalid')
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false;
        createProtection({
          name: this.name,
          nameEn: this.nameEn,
          descripcion: this.descripcion,
          descripcionEn: this.descripcionEn,
          tarifa: this.tarifa
        }).then(response => {
          if (response.data.data.createProteccion.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo();
            this.loadData();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.createProteccion.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La protección estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enableProtection({
            id: parseInt(id)
          }).then(response => {
            if (response.data.data.activeProteccion.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar la ciudad"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La protección no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disableProtection({
            id: parseInt(id)
          }).then(response => {
            if (response.data.data.desactiveProteccion.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar la ciudad"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    ActivarProtecciones(id) {
      activeProtectionAction({
        id: parseInt(id)
      }).then(response => {
        if (response.data.data.activeProtecciones.success) {
          this.proteccion_error = false;
          protectionsByIdAction({
            id: this.id
          }).then(response => {
            this.protecciones = response.data.data.proteccionesById;
          }).catch(err => console.log(err));
        }
        this.loadData();
      }).catch(error => console.log(err));
    },
    DesactivarProtecciones(id) {
      desactiveProtectionAction({
        id: parseInt(id)
      }).then(response => {
        if (response.data.data.desactiveProtecciones.success) {
          this.proteccion_error = false;
          protectionsByIdAction({
            id: this.id
          }).then(response => {
            this.protecciones = response.data.data.proteccionesById;
          }).catch(err => console.log(err));
          this.loadData();
        }
      }).catch(error => console.log(err));
    }
  },
  watch: {
    name(value) {
      if (value !== '') {
        if (document.getElementById('name_proteccion')) {
          document.getElementById('name_proteccion').classList.remove('is-invalid')
        }
      }
    },
    nameEn(value) {
      if (value !== '') {
        if (document.getElementById('name_proteccion_en')) {
          document.getElementById('name_proteccion_en').classList.remove('is-invalid')
        }
      }
    },
    descripcion(value) {
      if (value !== '') {
        if (document.getElementById('descripcion_proteccion')) {
          document.getElementById('descripcion_proteccion').classList.remove('is-invalid')
        }
      }
    },
    descripcionEn(value) {
      if (value !== '') {
        if (document.getElementById('descripcion_proteccion_en')) {
          document.getElementById('descripcion_proteccion_en').classList.remove('is-invalid')
        }
      }
    },
    tarifa(value) {
      if (value > 0) {
        if (document.getElementById('tarifa_proteccion')) {
          document.getElementById('tarifa_proteccion').classList.remove('is-invalid')
        }
      }
    }
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.descripcion-column {
  max-width: 200px; /* Ancho máximo para la columna */
  white-space: nowrap; /* Evita que el texto se desborde */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto recortado */
}

</style>
