<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import LoginForm from "@/components/Seguridad/LoginForm.vue";

export default {
  name: "Login",
  components: {
    BreadCrumb,
    LoginForm,
  },
};
</script>
