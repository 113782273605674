<template>
  <BreadCrumb PageTitle="Listado de protecciones" Subtitle="Administración"></BreadCrumb>
  <ProteccionList></ProteccionList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ProteccionList from "@/components/Nomencladores/ProteccionList.vue";

export default {
  name: "Ciudades",
  components: {BreadCrumb, ProteccionList}
}
</script>

<style scoped>

</style>
