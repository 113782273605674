<template>
  <BreadCrumb PageTitle="Reporte unidades próximas a servicio" Subtitle="Administración"></BreadCrumb>
  <ReportComponent :headers="headers" :url="url" :action="getServicesAuto" typeReport="reportUnitsServices"
                   :auto="true" :pagination="true"/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ReportComponent from "@/components/Reports/ReportComponent.vue";
import {getServicesAuto} from "@/actions";

export default {
  name: "ReportUnits",
  components: {ReportComponent, BreadCrumb},
  data() {
    return {
      headers : [
        {
          title: "Vehículo",
          value: "autoName",
          isMoney: false
        },
        {
          title: "Color",
          value: "color",
          isMoney: false
        },
        {
          title: "Categoría",
          value: "categoria.name",
          isMoney: false
        },
        {
          title: "Número Económico",
          value: "noEconomico",
          isMoney: false
        },
        {
          title: "Placa",
          value: "noPlaca",
          isMoney: false
        },
        {
          title: "Número de serie",
          value: "noSerie",
          isMoney: false
        },
        {
          title: "Estado",
          value: "estado",
          isMoney: false
        },
        {
          title: "Sucursal",
          value: "sucursal.name",
          isMoney: false
        },
        {
          title: "Ciudad",
          value: "sucursal.ciudad.name",
          isMoney: false
        },
      ],
      url: `${process.env.VUE_APP_URL_SERVICES}report/services/auto`
    }
  },
  methods: {
    getServicesAuto
  }
}
</script>

<style scoped>

</style>