import { cfdiUses } from "@/actions/constants";

export const fiscalRegimen = {
    "P": [
        {
            key: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
            value: "605",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
                {
                    code: "CN01",
                    name: "Nómina",
                }
            ],
        },
        {
            key: "Arrendamiento",
            value: "606",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Régimen de Enajenación o Adquisición de Bienes",
            value: "607",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Demás ingresos",
            value: "608",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Residentes en el Extranjero sin Establecimiento Permanente en México",
            value: "610",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
            ],
        },
        {
            key: "Ingresos por Dividendos (socios y accionistas)",
            value: "611",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Personas Físicas con Actividades Empresariales y Profesionales",
            value: "612",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Ingresos por intereses",
            value: "614",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Régimen de los ingresos por obtención de premios",
            value: "615",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Sin obligaciones fiscales",
            value: "616",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                }],
        },
        {
            key: "Incorporación Fiscal",
            value: "621",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            value: "625",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
                {
                    code: "D01",
                    name: "Honorarios médicos, dentales y gastos hospitalarios",
                },
                {
                    code: "D02",
                    name: "Gastos médicos por incapacidad o discapacidad",
                },
                {
                    code: "D03",
                    name: "Gastos funerales",
                },
                {
                    code: "D04",
                    name: "Donativos",
                },
                {
                    code: "D05",
                    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
                },
                {
                    code: "D06",
                    name: "Aportaciones voluntarias al SAR",
                },
                {
                    code: "D07",
                    name: "Primas por seguros de gastos médicos",
                },
                {
                    code: "D08",
                    name: "Gastos de transportación escolar obligatoria",
                },
                {
                    code: "D09",
                    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
                },
                {
                    code: "D10",
                    name: "Pagos por servicios educativos (colegiaturas)",
                },
            ],
        },
        {
            key: "Régimen Simplificado de Confianza",
            value: "626",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        }
    ],
    "M": [
        {
            key: "General de Ley Personas Morales",
            value: "601",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Personas Morales con Fines no Lucrativos",
            value: "603",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Residentes en el Extranjero sin Establecimiento Permanente en México",
            value: "610",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
            ],
        },
        {
            key: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            value: "620",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            value: "622",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Opcional para Grupos de Sociedades",
            value: "623",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Coordinados",
            value: "624",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        },
        {
            key: "Régimen Simplificado de Confianza",
            value: "626",
            cfdiUses: [
                {
                    code: "CP01",
                    name: "Pagos",
                },
                {
                    code: "S01",
                    name: "Sin efectos fiscales",
                },
                {
                    code: "G01",
                    name: "Adquisición de mercancías",
                },
                {
                    code: "G02",
                    name: "Devoluciones, descuentos o bonificaciones",
                },
                {
                    code: "G03",
                    name: "Gastos en general",
                },
                {
                    code: "I01",
                    name: "Construcciones",
                },
                {
                    code: "I02",
                    name: "Mobiliario y equipo de oficina por inversiones",
                },
                {
                    code: "I03",
                    name: "Equipo de transporte",
                },
                {
                    code: "I04",
                    name: "Equipo de computo y accesorios",
                },
                {
                    code: "I05",
                    name: "Dados, troqueles, moldes, matrices y herramental",
                },
                {
                    code: "I06",
                    name: "Comunicaciones telefónicas",
                },
                {
                    code: "I07",
                    name: "Comunicaciones satelitales",
                },
                {
                    code: "I08",
                    name: "Otra maquinaria y equipo",
                },
            ],
        }
    ]
}