<template>
  <BreadCrumb PageTitle="Mi perfil" Subtitle="Administración"></BreadCrumb>
  <Profile/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import Profile from "@/components/Profile/Profile.vue";

export default {
  name: "ProfileView",
  components: {Profile, BreadCrumb}
}
</script>

<style scoped>

</style>