<template>
  <BreadCrumb PageTitle="Listado de reservas" Subtitle="Reservas"></BreadCrumb>
  <ReservaList></ReservaList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import ReservaList from '@/components/Reserva/ReservaList.vue'

export default {
  name: "Clientes",
  components: {BreadCrumb, MarcaList, ReservaList}
}
</script>

<style scoped>

</style>
