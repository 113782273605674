<template>
  <BreadCrumb PageTitle="Listado de empresas" Subtitle="Administración"></BreadCrumb>
  <EmpresaList></EmpresaList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import EmpresaList from "@/components/Nomencladores/EmpresaList.vue";
export default {
  name: "Empresa",
  components: {BreadCrumb, EmpresaList}
}
</script>

<style scoped>

</style>
