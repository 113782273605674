<template>
  <BreadCrumb PageTitle="Listado de marcas" Subtitle="Administración"></BreadCrumb>
  <MarcaList></MarcaList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList}
}
</script>

<style scoped>

</style>
