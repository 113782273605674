<template>
  <BreadCrumb PageTitle="Reporte de estado de unidades" Subtitle="Administración"></BreadCrumb>
  <ReportStateUnit/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ReportStateUnit from "@/components/Reports/ReportStateUnit.vue";

export default {
  name: "ReportStateUnitView",
  components: {
    BreadCrumb,
    ReportStateUnit
  }
}
</script>

<style scoped>

</style>