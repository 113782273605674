<template>
  <div class="row">
    <div
        class="col-xl-6 col-lg-6 col-xxxl-6 col-sm-6"
        v-for="stat in stats"
        :key="stat.id"
    >
      <div class="card mb-25 border-0 rounded-0 bg-white stats-box">
        <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
          <div class="d-flex align-items-center">
            <div
                :class="[
                'icon position-relative rounded-circle text-center',
                stat.class,
              ]"
            >
              <i :class="stat.icon"></i>
            </div>
            <div class="title ms-15">
              <span
                  class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
              >
                {{ stat.title }}
              </span>
              <h4 class="fw-black mb-8 lh-1">
                {{ stat.currentValue }}
              </h4>
              <span class="fw-medium text-dark-emphasis">
                $ {{ formatearNumero(stat.previousValue==null?0:stat.previousValue) }} MXN
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

const TOTAL_CONTRATOS_ABIERTOS = gql`
query TotalContratosAbiertos {
    totalContratosAbiertos
}`;
const TOTAL_CONTRATOS_VENCIDOS = gql`
query TotalContratosVencidos {
    totalContratosVencidos
}`;
const TOTAL_CONTRATOS_CERRADOS = gql`
query TotalContratosCerrados {
    totalContratosCerrados
}`;
const TOTAL_CONTRATOS_CANCELADOS = gql`
query TotalContratosCancelados {
    totalContratosCancelados
}`;
const MONTO_CONTRATOS_VENCIDOS = gql`
query MontoContratosVencidos {
    montoContratosVencidos
}`;
const MONTO_CONTRATOS_ABIERTOS = gql`
query MontoContratosAbiertos {
    montoContratosAbiertos
}`;
const MONTO_CONTRATOS_CERRADOS = gql`
query MontoContratosCerrados {
    montoContratosCerrados
}`;
const MONTO_CONTRATOS_CANCELADOS = gql`
query MontoContratosCancelados {
    montoContratosCancelados
}`;
export default {
  name: "StatsBoxes",
  data() {
    return {
      stats: [
        {
          "id": 1,
          "class": "text-success",
          "icon": "flaticon-document",
          "title": "Contratos Abiertos",
          "sign": "",
          "currentValue": "0",
          "previousValue": "0",
          "classTwo": "text-success",
          "percent": "5.5",
          "iconUpDown": "flaticon-up-arrow"
        },
        {
          "id": 2,
          "class": "text-warning",
          "icon": "flaticon-document",
          "title": "Contratos Vencidos",
          "sign": "",
          "currentValue": "0",
          "previousValue": "0",
          "classTwo": "text-success",
          "percent": "2.5",
          "iconUpDown": "flaticon-up-arrow"
        },
        {
          "id": 3,
          "class": "text-secondary",
          "icon": "flaticon-document",
          "title": "Contratos Cerrados",
          "sign": "$",
          "currentValue": "0",
          "previousValue": "0",
          "classTwo": "text-danger",
          "percent": "1.5",
          "iconUpDown": "flaticon-down-arrow"
        },
        {
          "id": 4,
          "class": "text-danger",
          "icon": "flaticon-document",
          "title": "Contratos Cancelados",
          "sign": "$",
          "currentValue": "0",
          "previousValue": "0",
          "classTwo": "text-success",
          "percent": "11.5",
          "iconUpDown": "flaticon-up-arrow"
        }
      ],
      totalContratosAbiertos: -1,
      totalContratosVencidos: -1,
      totalContratosCerrados: -1,
      totalContratosCancelados: -1,
      montoContratosVencidos: -1,
      montoContratosAbiertos: -1,
      montoContratosCerrados: -1,
      montoContratosCancelados: -1,
    };
  },
  watch: {
    totalContratosAbiertos(value) {
      this.stats[0].currentValue = value
    },
    totalContratosVencidos(value) {
      this.stats[1].currentValue = value
    },
    totalContratosCerrados(value) {
      this.stats[2].currentValue = value
    },
    totalContratosCancelados(value) {
      this.stats[3].currentValue = value
    },
    montoContratosAbiertos(value) {
      this.stats[0].previousValue = value !== null ? value : 0
    },
    montoContratosVencidos(value) {
      this.stats[1].previousValue = value
    },
    montoContratosCerrados(value) {
      this.stats[2].previousValue = value
    },
    montoContratosCancelados(value) {
      this.stats[3].previousValue = value
    },
  },
  apollo: {
    totalContratosAbiertos: {
      query: TOTAL_CONTRATOS_ABIERTOS,
      fetchPolicy: "cache-and-network"
    },
    totalContratosVencidos: {
      query: TOTAL_CONTRATOS_VENCIDOS,
      fetchPolicy: "cache-and-network"
    },
    totalContratosCerrados: {
      query: TOTAL_CONTRATOS_CERRADOS,
      fetchPolicy: "cache-and-network"
    },
    totalContratosCancelados: {
      query: TOTAL_CONTRATOS_CANCELADOS,
      fetchPolicy: "cache-and-network"
    },
    montoContratosAbiertos: {
      query: MONTO_CONTRATOS_ABIERTOS,
      fetchPolicy: "cache-and-network"
    },
    montoContratosVencidos: {
      query: MONTO_CONTRATOS_VENCIDOS,
      fetchPolicy: "cache-and-network"
    },
    montoContratosCerrados: {
      query: MONTO_CONTRATOS_CERRADOS,
      fetchPolicy: "cache-and-network"
    },
    montoContratosCancelados: {
      query: MONTO_CONTRATOS_CANCELADOS,
      fetchPolicy: "cache-and-network"
    },
  },
  methods: {
    formatearNumero(numero) {
      const num = parseFloat(numero)
      return num.toLocaleString('en-US', {minimumFractionDigits: 2});
    },
  },
};
</script>