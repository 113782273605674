import {InMemoryCache, ApolloClient} from "@apollo/client";
import {createApolloProvider} from "@vue/apollo-option";

const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
    cache,
    uri: process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    //uri: "http://127.0.0.1:8000/graphql/",
    //uri: "https://sracws.qa.idooproject.com/graphql/",
    headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
    },
});

export const provider = createApolloProvider({
    defaultClient: apolloClient,
});
