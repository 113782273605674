<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar comisionista"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo comisionista
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tipo de comisionista
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Identificaci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Empresa
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in comisionistaByName" :key="item.id">

            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.tipo }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.identificacion }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.empresa.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="Desactivar comisionista" data-container="body"
                        style="padding: 0.8rem"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar comisionista" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="open_Details(item)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Ver detalles del cliente" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Editar comisionista" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center"></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo comisionista
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <MazInput
                v-model="nombre"
                no-radius
                :error="error_nombre"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Identificación</label>
            <MazInput
                v-model="identificacion"
                no-radius
                :error="error_identificacion"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Teléfono</label>
            <MazPhoneNumberInput
                v-model="telefono"
                v-model:country-code="codigo"
                no-radius
                :preferred-countries="['MX', 'US', 'CU']"
                :translations="{
                  countrySelector: {
                    placeholder: 'Código de país',
                    error: 'Seleccione un país',
                    searchPlaceholder: 'Buscar un país',
                  },
                  phoneInput: {
                    placeholder: 'Número de teléfono',
                    example: 'Ejemplo:',
                  },
                }"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Correo</label>
            <MazInput
                v-model="correo"
                no-radius
                :error="error_correo"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Empresa</label>
            <select class="form-control shadow-none rounded-0 text-black" v-model="empresa">
              <option value="" disabled>Seleccione una opción</option>
              <option v-for="item in empresaByName" :key="item.id" :value="item.id">{{ item.nombre }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Porcentaje</label>
            <input type="number" :min="0" :max="100" v-model="porcentaje"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Tipo de comisionista</label>
            <select class="form-control shadow-none rounded-0 text-black" v-model="tipo">
              <option value="" disabled>Seleccione una opción</option>
              <option value="Interno">Interno</option>
              <option value="Externo">Externo</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3" v-show="tipo==='Externo'">
            <label class="form-label ms-1">Tipo de comisionista externo</label>
            <select class="form-control shadow-none rounded-0 text-black" v-model="tipo_externo">
              <option value="" disabled>Seleccione una opción</option>
              <option value="Hostelería">Hostelería</option>
              <option value="Aeropuerto">Aeropuerto</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar comisionista
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <MazInput
                v-model="nombre"
                no-radius
                :error="error_nombre"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Identificación</label>
            <MazInput
                v-model="identificacion"
                no-radius
                :error="error_identificacion"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Teléfono</label>
            <MazPhoneNumberInput
                v-model="telefono"
                v-model:country-code="codigo"
                no-radius
                :preferred-countries="['MX', 'US', 'CU']"
                :translations="{
                  countrySelector: {
                    placeholder: 'Código de país',
                    error: 'Seleccione un país',
                    searchPlaceholder: 'Buscar un país',
                  },
                  phoneInput: {
                    placeholder: 'Número de teléfono',
                    example: 'Ejemplo:',
                  },
                }"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Correo</label>
            <MazInput
                v-model="correo"
                no-radius
                :error="error_correo"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Empresa</label>
            <select class="form-control shadow-none rounded-0 text-black" v-model="empresa">
              <option value="" disabled>Seleccione una opción</option>
              <option v-for="item in empresaByName" :key="item.id" :value="item.id">{{ item.nombre }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Porcentaje</label>
            <input type="number" :min="0" :max="100" v-model="porcentaje"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Tipo de comisionista</label>
            <select class="form-control shadow-none rounded-0 text-black" v-model="tipo">
              <option value="" disabled>Seleccione una opción</option>
              <option value="Interno">Interno</option>
              <option value="Externo">Externo</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3" v-show="tipo==='Externo'">
            <label class="form-label ms-1">Tipo de comisionista externo</label>
            <select class="form-control shadow-none rounded-0 text-black" v-model="tipo_externo">
              <option value="" disabled>Seleccione una opción</option>
              <option value="Hostelería">Hostelería</option>
              <option value="Aeropuerto">Aeropuerto</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles del comisionista
            <i @click="close_Details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-1 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Nombre:</h6>
            <p>{{ item.nombre }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Empresa:</h6>
            <p>{{ item.empresa.nombre }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Identificación:</h6>
            <p>{{ item.identificacion }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Telefono:</h6>
            <p>{{ item.telefono }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Correo:</h6>
            <p>{{ item.correo }}</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Porciento:</h6>
            <p>{{ item.porciento }}%</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Tipo de comisionista:</h6>
            <p>{{ item.tipo }}</p>
          </div>
          <div v-if="item.tipo==='Externo'" class="col-lg-6 col-md-6 col-sm-12">
            <h6>Tipo de comisionista externo:</h6>
            <p>{{ item.tipoExterno }}</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 d-flex justify-content-start mb-3">

        </div>
        <div class="col-md-6 col-lg-6 d-flex justify-content-end mb-3">
          <button @click="close_Details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazInput from 'maz-ui/components/MazInput'
import MazSelect from 'maz-ui/components/MazSelect'
import MazInputNumber from 'maz-ui/components/MazInputNumber'
import MazRadio from 'maz-ui/components/MazRadio'
import {ref} from "vue";
import {disableComicionista, enableComicionista, getComicionista} from "@/actions";

const BUSCAR = gql`
query ComisionistaByName($name:String!) {
                    comisionistaByName(name: $name) {
                        id
                        nombre
                        identificacion
                        codigoTelefono
                        telefono
                        correo
                        isActive
                        tipoExterno
                        tipo
                        porciento
                        empresa {
                            id
                            nombre
                            direccion
                            codigoTelefono
                            telefono
                            correo
                            rcf
                            isActive
                        }
                    }
                }`
const NUEVO = gql`
mutation CreateComisionista(
    $nombre:String!
    $identificacion:String!
    $codigoTelefono:String!
    $telefono:String!
    $correo:String!
    $empresa:ID!
    $tipoExterno:String!
    $tipo:String!
    $porciento:Decimal!
    ) {
    createComisionista(
        obj: {
            nombre: $nombre
            identificacion: $identificacion
            codigoTelefono: $codigoTelefono
            telefono: $telefono
            correo: $correo
            empresa: $empresa
            tipoExterno:$tipoExterno
            tipo:$tipo
            porciento:$porciento
        }
    ) {
        success
        error
    }
}
`;
const ACTUALIZAR = gql`
mutation UpdateComisionista (
    $id:ID!
    $nombre:String!
    $identificacion:String!
    $codigoTelefono:String!
    $telefono:String!
    $correo:String!
    $empresa:ID!
    $tipoExterno:String!
    $tipo:String!
    $porciento:Decimal!
    ){
    updateComisionista(
        obj: {
            id: $id
            nombre: $nombre
            identificacion: $identificacion
            codigoTelefono: $codigoTelefono
            telefono: $telefono
            correo: $correo
            empresa: $empresa
            tipoExterno:$tipoExterno
            tipo:$tipo
            porciento:$porciento
        }
    ) {
        success
        error
    }
}`;
const EMPRESAS = gql`
  query EmpresaByName {
    empresaByName(name: "") {
        id
        nombre
        direccion
        codigoTelefono
        telefono
        correo
        rcf
        isActive
    }
}`;

export default {
  name: "comisionistaList",
  components: {MazPhoneNumberInput, MazInput, MazSelect, MazInputNumber, MazRadio},
  data() {
    return {
      comisionistaByName: [],
      empresaByName: [],
      search: "",
      Nuevo_Modal: false,
      Details_Modal: false,
      Actualizar_Modal: false,
      nombre: "",
      identificacion: "",
      codigo: "",
      telefono: "",
      correo: "",
      empresa: 0,
      porcentaje: 0,
      error_porcentaje: false,
      error_nombre: false,
      error_identificacion: false,
      error_codigo: false,
      error_telefonoo: false,
      error_correo: false,
      error_empresa: false,
      id: 0,
      error_vacios: false,
      tipo: "",
      error_tipo: '',
      tipo_externo: "",
      error_tipo_externo: false,
    }
  },
  apollo: {
    comisionistaByName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: 'cache-and-network'
    },
    empresaByName: {
      query: EMPRESAS,
      fetchPolicy: 'cache-and-network'
    }
  },
  watch: {
    identificacion(value) {
      if (value !== "") {
        this.error_identificacion = false
      }
    },
    nombre(value) {
      if (value !== "") {
        this.error_nombre = false
      }
    },
    codigo(value) {
      if (value !== "") {
        this.error_codigo = false
      }
    },
    telefono(value) {
      if (value !== "") {
        this.error_telefono = false
      }
    },
    correo(value) {
      if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
        this.error_correo = false
      }
    },
    empresa(value) {
      if (value !== "") {
        this.error_empresa = false
      }
    },
    porcentaje(value) {
      if (value !== "") {
        this.error_porcentaje = false
      }
    },
    tipo(value) {
      if (value !== "") {
        this.error_tipo = false
      }
    },
    tipo_externo(value) {
      if (value !== "") {
        this.error_tipo_externo = false
      }
    },
  },
  methods: {
    loaddata() {
      getComicionista({name: this.search}).then(response => {
        this.comisionistaByName = response.data.data.comisionistaByName
      })
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.identificacion = item.identificacion
      this.nombre = item.nombre
      this.codigo = item.codigoTelefono
      this.telefono = item.telefono
      this.correo = item.correo
      this.empresa = item.empresa.id
      this.tipo = item.tipo
      this.tipo_externo = item.tipoExterno
      this.porcentaje = item.porciento
      this.id = item.id
      this.Actualizar_Modal = true
    },
    Editar() {
      var validacion = true
      if (this.identificacion === "") {
        this.error_identificacion = true
        validacion = false
      }
      if (this.nombre === "") {
        this.error_nombre = true
        validacion = false
      }
      if (this.codigo === "") {
        this.error_codigo = true
        validacion = false
      }
      if (this.telefono === "") {
        this.error_telefono = true
        validacion = false
      }
      if (this.correo === "") {
        this.error_correo = true
        validacion = false
      }
      if (this.empresa === 0) {
        this.error_empresa = true
        validacion = false
      }
      if (this.tipo === "") {
        this.error_tipo = true
        validacion = false
      }
      if (this.tipo_externo === "" && this.tipo === "Externo") {
        this.error_tipo_externo = true
        validacion = false
      }
      if (this.porcentaje === 0) {
        this.error_porcentaje = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        this.$apollo.mutate({
          mutation: ACTUALIZAR,
          variables: {
            id: this.id,
            nombre: this.nombre,
            identificacion: this.identificacion,
            codigoTelefono: this.codigo,
            porciento: parseFloat(this.porcentaje),
            telefono: this.telefono,
            correo: this.correo,
            empresa: this.empresa,
            tipoExterno: this.tipo === "Externo" ? this.tipo_externo : "",
            tipo: this.tipo,

          },
          refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
        }).then(response => {
          if (response.data.updateComisionista.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateComisionista.error
            });
            this.Close_update()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.Close_update()
        })
      } else {
        this.error_vacios = true
      }
    },
    Close_nuevo() {
      this.identificacion = ""
      this.nombre = ""
      this.codigo = ""
      this.telefono = ""
      this.correo = ""
      this.empresa = 0
      this.tipo = ""
      this.tipo_externo = ""
      this.id = 0
      this.Nuevo_Modal = false
      this.porcentaje = 0
    },
    Close_update() {
      this.identificacion = ""
      this.porcentaje = 0
      this.tipo = ""
      this.nombre = ""
      this.codigo = ""
      this.telefono = ""
      this.correo = ""
      this.empresa = 0
      this.tipo_externo = ""
      this.id = 0
      this.Actualizar_Modal = false
    },
    Crear() {
      var validacion = true
      if (this.identificacion === "") {
        this.error_identificacion = true
        validacion = false
      }
      if (this.nombre === "") {
        this.error_nombre = true
        validacion = false
      }
      if (this.codigo === "") {
        this.error_codigo = true
        validacion = false
      }
      if (this.telefono === "") {
        this.error_telefono = true
        validacion = false
      }
      if (this.correo === "") {
        this.error_correo = true
        validacion = false
      }
      if (this.empresa === 0) {
        this.error_empresa = true
        validacion = false
      }
      if (this.tipo === "") {
        this.error_tipo = true
        validacion = false
      }
      if (this.tipo_externo === "" && this.tipo === "Externo") {
        this.error_tipo_externo = true
        validacion = false
      }
      if (this.porcentaje === 0) {
        this.error_porcentaje = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        this.$apollo.mutate({
          mutation: NUEVO,
          variables: {
            nombre: this.nombre,
            identificacion: this.identificacion,
            codigoTelefono: this.codigo,
            telefono: this.telefono,
            correo: this.correo,
            empresa: this.empresa,
            tipoExterno: this.tipo === "Externo" ? this.tipo_externo : "",
            tipo: this.tipo,
            porciento: this.porcentaje
          },
          refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
        }).then(response => {
          if (response.data.createComisionista.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.createComisionista.error
            });
            this.Close_nuevo()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.Close_nuevo()
        })
      } else {
        this.error_vacios = true
        return false
      }

    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El comicionista estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enableComicionista({id: id}).then(response => {
            if (response.data.data.activeComisionista.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar el comisionista"
              });
            }
            this.loaddata()
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El comicionista no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disableComicionista({id: id}).then(response => {
            if (response.data.data.desactiveComisionista.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar el comisionista"
              });
            }
            this.loaddata()
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    close_Details() {
      this.item = null
      this.Details_Modal = false
    },
    open_Details(item) {
      this.item = item
      this.Details_Modal = true
    }
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
