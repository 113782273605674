<template>
  <BreadCrumb PageTitle="Listado de temporadas altas" Subtitle="Administración"></BreadCrumb>
  <HighSeasonList></HighSeasonList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import HighSeasonList from "@/components/Nomencladores/HighSeasonList.vue";

export default {
  name: "Estados",
  components: {BreadCrumb, HighSeasonList}
}
</script>

<style scoped>

</style>
