<template>
  <BreadCrumb PageTitle="Listado de dropoff" Subtitle="Administración"></BreadCrumb>
  <DropOffList></DropOffList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import DropOffList from "@/components/Nomencladores/DropOffList.vue"
export default {
  name: "Dropoff",
  components: {BreadCrumb, DropOffList}
}
</script>

<style scoped>

</style>
