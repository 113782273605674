<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar sucursal"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva sucursal
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Direcci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Ciudad
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in sucursalesByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.direccion }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.ciudad.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar sucursal" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar sucursal" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar sucursal" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva sucursal
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="name===''?'is-invalid':''" id="firstName" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Estado</label>
            <select :class="estado===0?'is-invalid':''" class="form-control" v-model="estado">
              <option value="0">Seleccione un estado</option>
              <option v-for="item in estadosByName" :key="item.id" :value="item.id"
                      v-show="item.isActive">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Ciudad</label>
            <select :class="ciudad===0?'is-invalid':''" :disabled="estado===0"
                    class="form-control shadow-none rounded-0 text-black" v-model="ciudad">
              <option value="0">Seleccione una ciudad</option>
              <option v-for="item in ciudadesByEstado" :key="item.id" :value="item.id"
                      v-show="item.isActive">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Direcci&oacute;n</label>
            <vue-google-autocomplete
                ref="address"
                id="map"
                classname="form-control border-bottom border-dark p-2 mb-3"
                placeholder="Escriba la dirección"
                v-on:placechanged="getAddressData"
                country="mx"
                @input="onInput"
                types="address"
            ></vue-google-autocomplete>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar sucursal
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="name===''?'is-invalid':''" id="firstName" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Estado</label>
            <select :class="estado===0?'is-invalid':''" class="form-control" v-model="estado">
              <option value="0">Seleccione un estado</option>
              <option v-for="item in estadosByName" :key="item.id" :value="item.id"
                      v-show="item.isActive">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Ciudad</label>
            <select :class="ciudad===0?'is-invalid':''" :disabled="estado===0"
                    class="form-control shadow-none rounded-0 text-black" v-model="ciudad">
              <option value="0">Seleccione una ciudad</option>
              <option v-for="item in ciudadesByEstado" :key="item.id" :value="item.id"
                      v-show="item.isActive">{{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Dirección</label>
            <textarea :class="direccion===''?'is-invalid':''" v-model="direccion"
                      class="form-control shadow-none rounded-0 text-black"></textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {decodeBase64} from "@/util";
import {
  createBranchOffice,
  disableBranchOffice,
  enableBranchOffice, getCitiesBranchOffice,
  getServicesSearchBranchOffice, getStatesBranchOffice,
  updateBranchOffice
} from "@/actions";

export default {
  name: "SucursalList",
  components: {VueGoogleAutocomplete},
  data() {
    return {
      sucursalesByName: [],
      estadosByName: [],
      ciudadesByEstado: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      name: '',
      direccion: '',
      ciudad: 0,
      estado: 0,
      id: 0,
      phone: "",
      error_vacios: false,
      loading: false,
    }
  },
  mounted() {
    this.loadData();
    getStatesBranchOffice().then(resp => {
      this.estadosByName = resp.data.data.estadosByName;
    }).catch(err => console.log(err));
  },
  watch: {
    estado(value) {
      getCitiesBranchOffice({
        estado: parseInt(this.estado)
      }).then(response => {
        this.ciudadesByEstado = response.data.data.ciudadesByEstado
      }).catch(err => console.log(err));
    }
  },
  methods: {
    onInput(event) {
      this.direccion = event.target.value;
    },
    loadData() {
      this.loading = true;
      getServicesSearchBranchOffice({
        name: this.search
      }).then(resp => {
        this.sucursalesByName = resp.data.data.sucursalesByName;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      });
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.name = item.name;
      this.direccion = item.direccion;
      this.estado = decodeBase64(item.ciudad.estado.id)
      this.ciudad = decodeBase64(item.ciudad.id)
      this.id = item.id;
      this.Actualizar_Modal = true
    },
    Close_nuevo() {
      this.name = '';
      this.estado = 0
      this.direccion = '';
      this.ciudad = 0;
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.name = '';
      this.direccion = '';
      this.estado = 0
      this.ciudad = 0;
      this.Actualizar_Modal = false
    },
    Crear() {
      if (this.name === '' || this.direccion === '' || this.ciudad === 0) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false;
      createBranchOffice({
        name: this.name,
        direccion: this.direccion,
        ciudad: parseInt(this.ciudad)
      }).then(response => {
        if (response.data.data.createSucursal.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_nuevo();
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.createSucursal.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Editar() {
      if (this.id === 0 || this.name === '' || this.direccion === '' || this.ciudad === 0) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false;
      updateBranchOffice({
        id: parseInt(this.id),
        name: this.name,
        direccion: this.direccion,
        ciudad: parseInt(this.ciudad)
      }).then(response => {
        if (response.data.data.updateSucursal.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update();
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.updateSucursal.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La sucursal estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enableBranchOffice({
            id: parseInt(id)
          }).then(response => {
            if (response.data.data.activeSucursal.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar la sucursal"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La sucursal no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disableBranchOffice({
            id: parseInt(id)
          }).then(response => {
            if (response.data.data.desactiveSucursal.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar la sucursal"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    getAddressData: function (addressData, placeResultData, id, object) {
      console.log(addressData)
      this.direccion = addressData.route + ", " + addressData.locality + ", " + addressData.administrative_area_level_1 + ", " + addressData.country
    }
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
