// export const srac_url_image = "http://srac-store-qa.s3.us-east-1.amazonaws.com/"
export const srac_url_image = "http://srac-store-prod.s3.us-east-1.amazonaws.com/"

// export const srac_backend_url = "https://srac-ws-qa-3utfp.ondigitalocean.app/"
export const srac_backend_url = "https://sracwsprodidoo.idooprod.com/"

export const cfdiUses = [
    {
        code: "G01",
        name: "Adquisición de mercancías",
        P: true,
        M: true
    },
    {
        code: "G02",
        name: "Devoluciones, descuentos o bonificaciones",
        P: true,
        M: true
    },
    {
        code: "G03",
        name: "Gastos en general",
        P: true,
        M: true
    },
    {
        code: "I01",
        name: "Construcciones",
        P: true,
        M: true
    },
    {
        code: "I02",
        name: "Mobiliario y equipo de oficina por inversiones",
        P: true,
        M: true
    },
    {
        code: "I03",
        name: "Equipo de transporte",
        P: true,
        M: true
    },
    {
        code: "I04",
        name: "Equipo de computo y accesorios",
        P: true,
        M: true
    },
    {
        code: "I05",
        name: "Dados, troqueles, moldes, matrices y herramental",
        P: true,
        M: true
    },
    {
        code: "I06",
        name: "Comunicaciones telefónicas",
        P: true,
        M: true
    },
    {
        code: "I07",
        name: "Comunicaciones satelitales",
        P: true,
        M: true
    },
    {
        code: "I08",
        name: "Otra maquinaria y equipo",
        P: true,
        M: true
    },
    {
        code: "D01",
        name: "Honorarios médicos, dentales y gastos hospitalarios",
        P: true,
        M: false
    },
    {
        code: "D02",
        name: "Gastos médicos por incapacidad o discapacidad",
        P: true,
        M: false
    },
    {
        code: "D03",
        name: "Gastos funerales",
        P: true,
        M: false
    },
    {
        code: "D04",
        name: "Donativos",
        P: true,
        M: false
    },
    {
        code: "D05",
        name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
        P: true,
        M: false
    },
    {
        code: "D06",
        name: "Aportaciones voluntarias al SAR",
        P: true,
        M: false
    },
    {
        code: "D07",
        name: "Primas por seguros de gastos médicos",
        P: true,
        M: false
    },
    {
        code: "D08",
        name: "Gastos de transportación escolar obligatoria",
        P: true,
        M: false
    },
    {
        code: "D09",
        name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
        P: true,
        M: false
    },
    {
        code: "D10",
        name: "Pagos por servicios educativos (colegiaturas)",
        P: true,
        M: false
    },
    {
        code: "S01",
        name: "Sin efectos fiscales",
        P: true,
        M: true
    },
    {
        code: "CP01",
        name: "Pagos",
        P: true,
        M: true
    },
    {
        code: "CN01",
        name: "Nómina",
        P: true,
        M: false
    }
]

export const paymentMethods = [
    {
        code: "PUE",
        name: "Pago en una sola exhibición"
    },
    {
        code: "PPD",
        name: "Pago en parcialidades o diferido"
    }
];

export const paymentWay = [
    {
        code: "01",
        name: "Efectivo"
    },
    {   
        code: "02",
        name: "Cheque nominativo"
    },
    {
        code: "03",
        name: "Transferencia electrónica de fondos"
    },
    {
        code: "04",
        name: "Tarjeta de crédito"
    },
    {   
        code: "05",
        name: "Monedero electrónico"
    },
    {
        code: "06",
        name: "Dinero electrónico"
    },
    {
        code: "08",
        name: "Vales de despensa"
    },
    {   
        code: "12",
        name: "Dación en pago"
    },
    {
        code: "13",
        name: "Pago por subrogación"
    },
    {
        code: "14",
        name: "Pago por consignación"
    },
    {   
        code: "15",
        name: "Condonación"
    },
    {
        code: "17",
        name: "Compensación"
    },
    {
        code: "23",
        name: "Novación"
    },
    {   
        code: "24",
        name: "Confusión"
    },
    {
        code: "25",
        name: "Remisión de deuda"
    },
    {
        code: "26",
        name: "Prescripción o caducidad"
    },
    {   
        code: "27",
        name: "A satisfacción del acreedor"
    },
    {
        code: "28",
        name: "Tarjeta de débito"
    },
    {
        code: "29",
        name: "Tarjeta de servicios"
    },
    {   
        code: "30",
        name: "Aplicación de anticipos"
    },
    {
        code: "31",
        name: "Intermediario pagos"
    },
    {
        code: "99",
        name: "Por definir"
    }
]