<template>
  <BreadCrumb PageTitle="Listado de facturas" Subtitle="Administración"></BreadCrumb>
  <Invoice/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import Invoice from "@/components/Invoice/Invoice.vue";

export default {
  name: "InvoiceView",
  components: {BreadCrumb, Invoice}
}
</script>

<style scoped>

</style>