<template>
  <BreadCrumb PageTitle="Listado de permisos por roles" Subtitle="Seguridad"></BreadCrumb>
  <PermisosGroupList></PermisosGroupList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import PermisosList from "@/components/Seguridad/PermisosList.vue";
import PermisosGroupList from "@/components/Seguridad/PermisosGroupList.vue";

export default {
  name: "Usuarios",
  components: {BreadCrumb, PermisosGroupList}
}
</script>

<style scoped>

</style>
