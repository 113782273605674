<template>
  <router-view></router-view>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState([
      "isAuth",
      "SidenavOpen",
    ]),
  },
  watch: {
    SidenavOpen(value) {
      if (value) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.modal_cargando {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal_cargando {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal_cargando.show {
  opacity: 1;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 6px solid #fff; /* Color del borde superior */
  border-top: 6px solid #EF2929; /* Color del borde */
  border-radius: 50%;
  animation: spin 2s linear infinite; /* Duración de la animación */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
