<template>
  <div class="card grafico bg-white">
    <div class="card-header text-start">Categor&iacute;as con m&aacute;s dinero generado</div>
    <div class="card-body">
      <table v-if="!loading" class="table table-responsive">
        <thead>
        <tr>
          <th>Imagen</th>
          <th>Categor&iacute;a</th>
          <th>Monto</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="item in categories" :key="`${item.category.name}${item.mount}`">
            <td>
              <div class="ms-3" v-if="item.category.imagen!==''">
                <img class="rounded-circle img-categoria cursor-pointer"
                     :src="getImageUrl(item.category.imagen)">
              </div>
              <div class="ms-3" v-else>
                <img class="rounded-circle img-categoria cursor-pointer"
                     src="@/assets/images/autos/no-photo.jpg" alt="image">
              </div>
            </td>
            <td>{{ item.category.name }}</td>
            <td>$ {{ formatearNumero(item.mount) }} MXN</td>
          </tr>
        </tbody>
      </table>
      <LoadingDatas v-else/>
    </div>
  </div>
</template>

<script>
import {categoriasByDinero} from "@/actions";
import LoadingDatas from "@/components/LoadingDatas.vue";
import {srac_url_image} from "@/actions/constants";

export default {
  name: "Ventas",
  components: {LoadingDatas},
  data() {
    return {
      categories: [],
      loading: false,
    }
  },
  methods: {
    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    loadData() {
      this.loading = true;
      categoriasByDinero().then(resp => {
        this.categories = resp.data.data.categoriasByDinero;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    formatearNumero(numero) {
      const num = parseFloat(numero).toFixed(2);
      return parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2});
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
  .img-categoria {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
</style>
