<template>
  <BreadCrumb PageTitle="Listado de versiones de la App" Subtitle="Administración"></BreadCrumb>
  <AppVersionList></AppVersionList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import CiudadList from "@/components/Nomencladores/CiudadList.vue";
import AppVersionList from "@/components/Nomencladores/AppVersionList.vue";

export default {
  name: "Ciudades",
  components: {AppVersionList, BreadCrumb, CiudadList}
}
</script>

<style scoped>

</style>
