<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-lg-start">
        <button class="btn btn-secondary" @click="()=>dialogSearch=true">
          <i class="flaticon-search"></i>
        </button>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0" v-if="!loading">
        <a class="btn btn-link" :href="urlDownload" target="_blank" title="Descargar reporte en Excel">
          <i class="flaticon-download-circular-button"></i>
        </a>
      </div>
      <div v-else class="spinner-border text-danger icon-loading-custom" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              N&uacute;mero econ&oacute;mico
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Marca
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Modelo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Estado
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Ciudad
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.noEconomico }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.categoria.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.modelo.marca.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.modelo.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.estado }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.sucursal ? item.sucursal.name : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.sucursal ? item.sucursal.ciudad.name : "" }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
  <div class="text-dark">
    <div v-if="dialogSearch" :class="['modal', { 'show': dialogSearch }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            B&uacute;squeda avanzada
            <i @click="()=>dialogSearch=false" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
            <label for="branchOfficeReport" class="form-label d-flex">Sucursal</label>
            <select v-model="branchOffice" class="form-control form-select" :disabled="loading" id="branchOfficeReport">
              <option value="all" selected>Todas</option>
              <option v-for="bo in branchOffices" :value="bo.id" :key="bo.id">{{bo.name}}</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <label for="stateUnits" class="form-label d-flex">Estado</label>
            <select v-model="state" class="form-control form-select" :disabled="loading" id="stateUnits">
              <option value="all">Todos</option>
              <option value="Rentado">Rentado</option>
              <option value="Disponible">Disponible</option>
              <option value="Mantenimiento">Mantenimiento</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="()=>dialogSearch=false" :disabled="loading"
                    class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="()=>{
              dialogSearch=false;
              loadData(true);
            }" class="btn btn-danger d-block w-100" type="button"
                    :disabled="loading">Buscar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  getBranchOffices,
  searchCarReport,
  searchNextCarReport,
  searchPrevCarReport
} from "@/actions";

export default {
  name: "ReportStateUnit",
  data() {
    return {
      loading: false,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,

      url: `${process.env.VUE_APP_URL_SERVICES}report/state/auto`,

      branchOffices: [],
      branchOffice: "all",
      data: [],
      state: "all",
      dialogSearch: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark',
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  computed: {
    urlDownload(){
      let urlFinal = `${this.url}?token=${sessionStorage.getItem('token')}&`;
      if (this.state !== null && this.state !== "all"){
        urlFinal = `${urlFinal}state=${this.state}&`;
      }
      if (this.branchOffice !== null && this.branchOffice !== "" && this.branchOffice !== "all"){
        urlFinal = `${urlFinal}branchOffice=${this.branchOffice}&`;
      }
      return urlFinal.substring(0, urlFinal.length-1)
    }
  },
  mounted() {
    this.loadData(true);
    this.loadBranchOffices();
  },
  methods: {
    loadBranchOffices(){
      getBranchOffices().then(resp=>{
        this.branchOffices = resp.data.data.searchBranchoffices.edges.map(e=>e.node);
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      })
    },
    loadData(init = false) {
      this.loading = true
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchCarReport({
        state: this.state && this.state !== "all"?this.state:"",
        branchOffice: this.branchOffice && this.branchOffice!=="all"?this.branchOffice:null,
        before: before,
        after: next,
        first: this.cant
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchAutos.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchAutos.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchAutos.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchAutos.pageInfo.endCursor;
        this.data = resp.data.data.searchAutos.edges.map(value => {
          return value.node;
        });
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      searchNextCarReport({
        state: this.state && this.state !== "all"?this.state:"",
        branchOffice: this.branchOffice && this.branchOffice!=="all"?this.branchOffice:null,
        after: this.endCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchAutos.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchAutos.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchAutos.pageInfo.endCursor;
        this.data = resp.data.data.searchAutos.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevCarReport({
        state: this.state && this.state !== "all"?this.state:"",
        branchOffice: this.branchOffice && this.branchOffice!=="all"?this.branchOffice:null,
        before: this.startCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchAutos.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchAutos.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchAutos.pageInfo.endCursor;
        this.data = resp.data.data.searchAutos.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    cant(value){
      this.loadData(true);
    }
  }
}
</script>

<style scoped>
  .icon-loading-custom {
    width: 22px !important;
    height: 22px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 1rem;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
    width: 25rem;
  }

  @media (max-width: 767px) {
    .modal-content {
      border-radius: 15px;
      padding: 10px;
      width: 90%;
    }
  }

  .modal {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .modal.show {
    opacity: 1;
  }
</style>