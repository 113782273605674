<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <div class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar usuario"
          />
          <button class="bg-transparent text-primary transition p-0 border-0" @click="loadDatas(true)">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </div>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo usuario
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
        <!--select
            class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10"
        >
          <option selected>Todos</option>
          <option value="1">Activos</option>
          <option value="2">Desactivados</option>
        </select-->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0"
            >
              Apellidos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Correo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0"
            >
              ROL
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in usersByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.firstName }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.lastName }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              {{ item.email }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.groups?.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar usuario" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar usuario" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar usuario" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo usuario
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="firstName===''?'is-invalid':'is-valid'" id="firstName" autofocus v-model="firstName"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Apellidos</label>
            <input :class="lastName===''?'is-invalid':'is-valid'" id="lastName" v-model="lastName"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Usuario</label>
            <input :class="username===''?'is-invalid':'is-valid'" id="username" v-model="username" autocomplete="false"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Correo</label>
            <input :class="email===''?'is-invalid':'is-valid'" id="email" autocomplete="false" v-model="email"
                   type="email" class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Contrase&ntilde;a</label>
            <input :class="password===''?'is-invalid':'is-valid'" id="password" autocomplete="false" v-model="password"
                   type="password" :disabled="saveChanges" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Confirmar contrase&ntilde;a</label>
            <input :class="password1!==password?'is-invalid':'is-valid'" id="password1" autocomplete="false"
                   v-model="password1"
                   type="password" :disabled="saveChanges" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Seleccione el Rol</label>
            <select :disabled="saveChanges" :class="group===0?'is-invalid':'is-valid'" v-model="group"
                    class="form-control shadow-none rounded-0 text-black">
              <option disabled value="0">Seleccione un rol</option>
              <option v-for="group in gruposByName" :value="group.id">{{ group.name }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">N&uacute;mero de Tel&eacute;fono</label>
            <input :disabled="saveChanges"
                   :class="phone==='' || phone === null || phone.toString().length!==10?'is-invalid':'is-valid'"
                   v-model="phone" type="number" class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os y el n&uacute;mero de tel&eacute;fono 10 d&iacute;gitos
              </span>
          </div>
          <div v-show="error_password" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
               Las contrase&ntilde;as no coinciden
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button :disabled="saveChanges" @click="Close_nuevo" class="btn btn-secondary d-block w-100"
                    type="button">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="saveChanges || isValidCreate" @click="Crear" class="btn btn-danger d-block w-100"
                    type="button">
              <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar usuario
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :disabled="saveChanges" :class="firstName===''?'is-invalid':'is-valid'" id="firstName" autofocus
                   v-model="firstName" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Apellidos</label>
            <input :disabled="saveChanges" :class="lastName===''?'is-invalid':'is-valid'" id="lastName"
                   v-model="lastName" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Usuario</label>
            <input :disabled="saveChanges" :class="username===''?'is-invalid':'is-valid'" id="username"
                   v-model="username" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Correo</label>
            <input :disabled="saveChanges" :class="email===''?'is-invalid':'is-valid'" id="email" autocomplete="false"
                   v-model="email" type="email" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">N&uacute;mero de Tel&eacute;fono</label>
            <input :disabled="saveChanges" :class="phone==='' || phone === null
                   || phone.toString().length!==10?'is-invalid':'is-valid'" v-model="phone" type="number"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os y el n&uacute;mero de tel&eacute;fono 10 d&iacute;gitos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button :disabled="saveChanges" @click="Close_update" class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="saveChanges || isValidUpdate" @click="Editar" class="btn btn-danger d-block w-100"
                    type="button">
              <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Actualizar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegistroForm from "@/components/Seguridad/RegistroForm.vue";
import Swal from "sweetalert2";
import {
  createUser,
  disableUser,
  enableUser,
  searchGroupsForUser,
  searchNextUsers,
  searchPrevUsers,
  searchUsers,
  updateUser
} from "@/actions";
import {decodeBase64} from "@/util";

export default {
  name: "UsuariosList",
  components: {RegistroForm},
  data() {
    return {
      usersByName: [],
      gruposByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      password1: "",
      cant: 10,
      id: 0,
      group: 0,
      phone: "",
      error_vacios: false,
      error_password: false,
      saveChanges: false,
      loading: false,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    searchGroupsForUser().then(resp => {
      this.gruposByName = resp.data.data.searchExtendGroups.edges.map(e => e.node);
    }).catch(err => console.log(err));
    this.loadDatas(true);
  },
  computed: {
    isValidCreate() {
      return this.firstName === "" || this.lastName === "" || this.username === "" || this.email === "" ||
          this.password === '' || this.password1 !== this.password || this.phone === "" || this.phone === null
          || this.phone.toString().length !== 10 || this.group === null;
    },
    isValidUpdate() {
      return this.firstName === "" || this.lastName === "" || this.username === "" || this.email === "" ||
          this.phone === "" || this.phone === null || this.phone.toString().length !== 10 || this.group === null;
    }
  },
  methods: {
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Close_nuevo() {
      this.firstName = "";
      this.email = "";
      this.lastName = "";
      this.username = "";
      this.password = "";
      this.password1 = "";
      this.group = 0;
      this.phone = "";
      this.Nuevo_Modal = false
    },
    Crear() {
      if (this.firstName === "" || this.lastName === "" || this.username === "" || this.email === "" ||
          this.password === "" || this.password1 === "" || this.phone === "" || this.group === null ||
          this.phone.toString().length !== 10) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      const password1 = document.getElementById('password1')
      if (this.password !== this.password1) {
        password1.classList.add('is-invalid')
        this.error_password = true
        return false
      }
      password1.classList.remove('is-invalid');
      this.saveChanges = true;
      createUser({
        firstName: this.firstName,
        email: this.email,
        lastName: this.lastName,
        username: this.username,
        password: this.password,
        password1: this.password1,
        group: decodeBase64(this.group),
        phone: this.phone.toString()
      }).then(response => {
        if (response.data.data.nuevoUsuario.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_nuevo();
          this.loadDatas(true);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.nuevoUsuario.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.saveChanges = false;
      })
    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El usuario podrá acceder al sistema",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enableUser({
            id: decodeBase64(id)
          }).then(response => {
            if (response.data.data.activarUsuario.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadDatas();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar el usuario"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error.message
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El usuario no podrá acceder al sistema",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disableUser({
            id: decodeBase64(id)
          }).then(response => {
            if (response.data.data.desactivarUsuario.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadDatas();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar el usuario"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error.message
            });
          })
        }
      })
    },
    Actualizar(item) {
      this.firstName = item.firstName;
      this.email = item.email;
      this.lastName = item.lastName;
      this.username = item.username;
      this.group = item.groups ? item.groups.id : null;
      this.phone = item.phone;
      this.id = decodeBase64(item.id)
      this.Actualizar_Modal = true
    },
    Close_update() {
      this.firstName = "";
      this.email = "";
      this.lastName = "";
      this.username = "";
      this.password = "";
      this.password1 = "";
      this.group = 0;
      this.phone = "";
      this.Actualizar_Modal = false
    },
    Editar() {
      this.saveChanges = true;
      updateUser({
        firstName: this.firstName,
        email: this.email,
        lastName: this.lastName,
        username: this.username,
        phone: this.phone.toString(),
        id: decodeBase64(this.id)
      }).then(response => {
        if (response.data.data.editarUsuario.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.editarUsuario.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.saveChanges = false;
      })
    },
    loadDatas(init = false) {
      this.loading = true
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchUsers({
        name: this.search,
        before: before,
        after: next,
        first: this.cant
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchExtendUsers.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchExtendUsers.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchExtendUsers.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchExtendUsers.pageInfo.endCursor;
        this.usersByName = resp.data.data.searchExtendUsers.edges.map(value => {
          return value.node;
        });
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      searchNextUsers({
        name: this.search,
        after: this.endCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchExtendUsers.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchExtendUsers.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchExtendUsers.pageInfo.endCursor;
        this.usersByName = resp.data.data.searchExtendUsers.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevUsers({
        name: this.search,
        before: this.startCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchExtendUsers.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchExtendUsers.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchExtendUsers.pageInfo.endCursor;
        this.usersByName = resp.data.data.searchExtendUsers.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    cant(value) {
      this.loadDatas(true);
    },
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
