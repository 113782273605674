<template>
<BreadCrumb PageTitle="Listado de usuarios" Subtitle="Seguridad"></BreadCrumb>
  <UsuariosList></UsuariosList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import UsuariosList from "@/components/Seguridad/UsuariosList.vue";
export default {
  name: "Usuarios",
  components: {BreadCrumb, UsuariosList}
}
</script>

<style scoped>

</style>
