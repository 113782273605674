<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <div class="search-box position-relative">
          <input
              type="text" v-model="search"
              @keyup.enter="()=>loadDatas(true)"
              @change="()=>loadDatas(true)"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar factura"
          />
          <button class="bg-transparent text-primary transition p-0 border-0" @click="loadDatas(true)">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </div>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0" v-if="!loadingBalance">
        <h6>
          <strong>Timbres disponibles: </strong>
          <span :class="availableNum>0?'good-balance':'not-balance'">{{availableNum}}</span>
        </h6>
      </div>
      <div v-else class="spinner-border text-danger icon-loading-custom" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Cliente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0"
            >
              RFC
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Contrato
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in invoices" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.client }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.rfc }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.contrato.contratoNo }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <a data-bs-toggle="tooltip" :href="generateUrlDocument(item.pdfFile)" target="_blank"
                   data-bs-placement="top"
                   style="padding: 0.8rem"
                   title="Descargar pdf factura" data-container="body" data-animation="true"
                   class="btn text-info">
                  <i class="flaticon-document d-flex justify-content-center align-items-center "></i>
                </a>
                <a data-bs-toggle="tooltip" :href="generateUrlDocument(item.xmlFile)" target="_blank"
                   data-bs-placement="top"
                   style="padding: 0.8rem"
                   title="Descargar xml factura" data-container="body" data-animation="true"
                   class="btn text-info">
                  <i class="flaticon-download d-flex justify-content-center align-items-center "></i>
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBalance,
  searchInvoices,
  searchNextInvoices,
  searchPrevInvoices
} from "@/actions";
import Swal from "sweetalert2";

export default {
  name: "Invoice",
  data(){
    return {
      loading: true,
      loadingBalance: true,
      availableNum: 0,
      invoices: [],
      cant: 10,
      search: "",

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark',
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.loadDatas(true);
  },
  watch: {
    cant(value){
      this.loadDatas(true);
    }
  },
  methods: {
    generateUrlDocument(url) {
      return `${process.env.VUE_APP_URL_IMAGE}${url}`;
    },
    loadDatas(init = false) {
      this.loading = true;
      this.loadingBalance = true;
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchInvoices({
        name: this.search,
        before: before,
        after: next,
        first: this.cant
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchInvoices.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchInvoices.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchInvoices.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchInvoices.pageInfo.endCursor;
        this.invoices = resp.data.data.searchInvoices.edges.map(value => value.node);
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
      getBalance().then(resp=>{
        this.availableNum = resp.data.data.searchBalance;
      }).catch(err=>console.log(err)).finally(()=>{
        this.loadingBalance = false;
      });
    },
    nextPage() {
      this.loading = true;
      this.loadingBalance = true;
      searchNextInvoices({
        name: this.search,
        after: this.endCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchInvoices.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchInvoices.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchInvoices.pageInfo.endCursor;
        this.invoices = resp.data.data.searchInvoices.edges.map(value => value.node);
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
      getBalance().then(resp=>{
        this.availableNum = resp.data.data.searchBalance;
      }).catch(err=>console.log(err)).finally(()=>{
        this.loadingBalance = false;
      });
    },
    prevPage() {
      this.loading = true;
      this.loadingBalance = true;
      searchPrevInvoices({
        name: this.search,
        before: this.startCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchInvoices.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchInvoices.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchInvoices.pageInfo.endCursor;
        this.invoices = resp.data.data.searchInvoices.edges.map(value => value.node);
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
      getBalance().then(resp=>{
        this.availableNum = resp.data.data.searchBalance;
      }).catch(err=>console.log(err)).finally(()=>{
        this.loadingBalance = false;
      });
    },
  }
}
</script>

<style scoped>
.not-balance{
  color: #c11212;
}

.good-balance{
  color: #46a72c;
}

.icon-loading-custom {
  width: 22px !important;
  height: 22px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 1rem;
}
</style>