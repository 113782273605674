<template>
  <BreadCrumb PageTitle="Servicio de vehículos" Subtitle="Reportes"></BreadCrumb>
  <CarService></CarService>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import CarService from "@/components/Reports/CarService";

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, CarService}
}
</script>

<style scoped>

</style>
