<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading}]">
      <div class="spinner"></div>
    </div>
  </div>
  <MazTabs v-model="currentTab">
    <MazTabsBar :items="tabs" />
    <MazTabsContent class="pt-1">
      <MazTabsContentItem :tab="1" class="maz-py-4 bg-white pt-1 pb-1" style="padding-left: 1.7rem !important">
        <div class="container-fluid">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 mt-2 row">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label for="firstNameProfile" class="form-label text-dark d-flex ml-1">Nombre</label>
              <input id="firstNameProfile" class="form-control" :class="isValidFirstName?'is-valid':'is-invalid'"
                     v-model="firstName" :disabled="saveChanges" required/>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label for="lastNameProfile" class="form-label text-dark d-flex ml-1">Apellidos</label>
              <input id="lastNameProfile" class="form-control" :class="isValidLastName?'is-valid':'is-invalid'"
                     v-model="lastName" :disabled="saveChanges" required/>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 row">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label for="usernameProfile" class="form-label text-dark d-flex ml-1">Usuario</label>
              <input id="usernameProfile" class="form-control" :class="isValidUsername?'is-valid':'is-invalid'"
                     v-model="username" :disabled="saveChanges" required/>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label for="emailProfile" class="form-label text-dark d-flex ml-1">Correo</label>
              <input id="emailProfile" class="form-control" :class="isValidEmail?'is-valid':'is-invalid'"
                     v-model="email" :disabled="saveChanges" type="email" required/>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <MazPhoneNumberInput
                v-model="phone"
                v-model:country-code="code"
                no-radius
                :disabled="saveChanges"
                :error="!isValidPhone"
                :preferred-countries="['MX', 'US', 'CU']"
                :translations="{
                  countrySelector: {
                    placeholder: 'Código de país',
                    error: 'Seleccione un país',
                    searchPlaceholder: 'Buscar un país',
                  },
                  phoneInput: {
                    placeholder: 'Número de teléfono',
                    example: 'Ejemplo:',
                  },
                }"
            />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 row">
            <button class="btn btn-danger" @click="saveChangesAction" :disabled="saveChanges || !isValid">
              <div v-if="saveChanges" class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Guardar</span>
            </button>
          </div>
        </div>
      </MazTabsContentItem>
      <MazTabsContentItem :tab="2"  class="maz-py-4 bg-white pt-1 pb-1"  style="padding-left: 1.7rem !important">
        <div class="container-fluid">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 mt-2 row">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label for="newPasswordProfile" class="form-label text-dark d-flex ml-1">Nueva contrase&ntilde;a</label>
              <input id="newPasswordProfile" class="form-control" :class="isValidPassword?'is-valid':'is-invalid'"
                     v-model="password" :disabled="saveChanges" type="password" required/>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label for="newRPasswordProfile" class="form-label text-dark d-flex ml-1">Repetir contrase&ntilde;a</label>
              <input id="newRPasswordProfile" class="form-control" :class="isValidPasswordRepeat?'is-valid':'is-invalid'"
                     v-model="passwordRepeat" :disabled="saveChanges" type="password" required/>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 row">
            <button class="btn btn-danger" @click="changePasswordAction"
                    :disabled="saveChanges || !isValidChangePassword">
              <div v-if="saveChanges" class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Guardar</span>
            </button>
          </div>
        </div>
      </MazTabsContentItem>
    </MazTabsContent>
  </MazTabs>
</template>

<script>
import {
  changePasswordProfile,
  getUserCurrent,
  saveChangesProfile
} from "@/actions";
import Swal from "sweetalert2";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import MazTabs from "maz-ui/components/MazTabs";
import MazTabsContent from "maz-ui/components/MazTabsContent";
import MazTabsContentItem from "maz-ui/components/MazTabsContentItem";
import MazTabsBar from "maz-ui/components/MazTabsBar";

export default {
  name: "Profile",
  components: {
    MazPhoneNumberInput,
    MazTabs,
    MazTabsContent,
    MazTabsContentItem,
    MazTabsBar
  },
  data() {
    return {
      currentTab: 1,
      tabs: ['Mi perfil', 'Cambiar contraseña'],

      loading: false,
      saveChanges: false,

      errorPhone: false,

      firstName: "",
      lastName: "",
      email: "",
      username: "",
      phone: "",
      code: "MX",

      password: "",
      passwordRepeat: "",

      dialogPassword: false,

      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  computed: {
    isValidPassword(){
      return this.password !== null && this.password.length > 7;
    },
    isValidPasswordRepeat(){
      return this.password === this.passwordRepeat;
    },
    isValidChangePassword(){
      return this.isValidPassword && this.isValidPasswordRepeat;
    },
    isValidEmail(){
      return this.email !== null && this.email !== "" &&
          /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email)
    },
    isValidFirstName(){
      return this.firstName !== null && this.firstName !== "" && /\w+([A-Z, a-z,Ñ,ñ])/.test(this.firstName);
    },
    isValidLastName(){
      return this.lastName !== null && this.lastName !== "" && /[A-Z, Ñ][a-z, ñ]/.test(this.lastName);
    },
    isValidUsername(){
      return this.username !== null && this.username !== "";
    },
    isValidPhone(){
      return this.phone !== null && this.phone !== "" && this.code !== null && this.code !== "";
    },
    isValid(){
      return this.isValidFirstName && this.isValidLastName && this.isValidEmail && this.isValidPhone
          && this.isValidUsername;
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(){
      this.loading = true;
      getUserCurrent().then(resp=>{
        const data = resp.data.data.getUserCurrent;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phone = data.phone;
        this.username = data.username;
        this.code = data.code;
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.loading = false;
      });
    },
    saveChangesAction(){
      this.saveChanges = true;
      saveChangesProfile({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        username: this.username,
        code: this.code
      }).then(resp=>{
        const data = resp.data.data.editProfile;
        if(data.error){
          this.toast.fire({
            icon: "error",
            title: data.error
          });
        }
        else{
          this.firstName = data.info.firstName;
          this.lastName = data.info.lastName;
          this.email = data.info.email;
          this.phone = data.info.phone;
          this.username = data.info.username;
          this.code = data.info.code;
          this.toast.fire({
            icon: "success",
            title: "Datos actualizados"
          });
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      });
    },
    changePasswordAction(){
      this.saveChanges = true;
      changePasswordProfile({
        password: this.password
      }).then(resp=>{
        const data = resp.data.data.changePasswordProfile;
        if(data.error){
          this.toast.fire({
            icon: "error",
            title: data.error
          });
        }
        else{
          this.toast.fire({
            icon: "success",
            title: "Contraseña actualizada"
          });
          this.dialogPassword = false;
          this.password = "";
          this.passwordRepeat = "";
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      });
    }
  }
}
</script>

<style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
    width: 60%;
  }

  @media (max-width: 767px) {
    .modal-content {
      border-radius: 15px;
      padding: 10px;
      width: 90%;
    }
  }

  .modal {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .modal.show {
    opacity: 1;
  }
</style>