<template>
  <BreadCrumb PageTitle="Listado de paises" Subtitle="Administración"></BreadCrumb>
  <PaisesList></PaisesList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import PaisesList from "@/components/Nomencladores/PaiseseList.vue";

export default {
  name: "Estados",
  components: {BreadCrumb, PaisesList}
}
</script>

<style scoped>

</style>
