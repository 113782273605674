<template>
  <div class="card mb-25 border-0 rounded-0 welcome-box" style="height:297px">
    <div class="card-body pe-15 pe-sm-20 pe-md-0 pb-0 pt-15 pt-sm-20">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="title position-relative">
            <h6 class="fw-semibold mb-8">
              Buen d&iacute;a, <span class="fw-bold">{{ email }}!</span>
            </h6>
            <span class="d-block text-black-emphasis fs-md-15 fs-lg-16">
              Como van sus ventas hasta hoy.
            </span>
          </div>
          <ul class="ps-0 mb-0 list-unstyled">
            <li
                class="d-inline-block text-uppercase fw-medium fs-13 text-black-emphasis position-relative"
            >
              Ventas totales hasta hoy
              <h6 class="d-block fw-black lh-1 text-black mt-5 mt-md-10">
                $ {{formatearNumero(totalVentas)}} MXN
              </h6>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-md-6 text-center mt-15 mt-md-0">
          <img class="img-logo"
              src="@/assets/images/logo3.png"
              alt="welcome-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import gql from "graphql-tag";

const TOTAL_VENTAS = gql`
query TotalVentas {
    totalVentas
}`
export default {
  name: "WhatHappening",
  components: {},
  data() {
    return {
      totalVentas:0
    }
  },
  apollo: {
    totalVentas: {
      query: TOTAL_VENTAS,
      fetchPolicy: "cache-and-network"
    }
  },
  methods:{
    formatearNumero(numero) {
      const num = parseFloat(numero)
      return num.toLocaleString('en-US', {minimumFractionDigits: 2});
    },
  },
  computed: {
    ...mapState([
      "email",
    ])
  }
};
</script>
<style scoped>
.img-logo{
  object-fit:contain;
  height:250px
}
</style>