<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-lg-start">
        <button class="btn btn-secondary" @click="()=>dialogSearch=true">
          <i class="flaticon-search"></i>
        </button>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0" v-if="!loading">
        <a class="btn btn-link" @click="exportToExcel">
          <i class="flaticon-download-circular-button"></i>
        </a>
      </div>
      <div v-else class="spinner-border text-danger icon-loading-custom" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Veh&iacute;culo
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              No. Econ&oacute;mico
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Color
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              No. de serie
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Placa
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Monto de daños
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.vehiculo.modelo.name }} - {{ item.vehiculo.modelo.marca.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.vehiculo.noEconomico }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.vehiculo.color }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.vehiculo.categoria.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.vehiculo.noSerie }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.vehiculo.noPlaca }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.contratoSucursalEntrega.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ transfMount(totalDamages(item.damagescontractSet)) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="pageSizes">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="loadData(page-1)" :disabled="page===1">
          <i class="flaticon-chevron-1 "></i>
        </button>
        <button class="btn btn-dark" style="height: 40px" @click="loadData(page+1)" :disabled="page===pages">
          <i class="flaticon-chevron"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="text-dark">
    <div v-if="dialogSearch" :class="['modal', { 'show': dialogSearch }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            B&uacute;squeda avanzada
            <i @click="()=>dialogSearch=false" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-2">
            <label for="branchOfficeReport" class="form-label d-flex">Sucursal</label>
            <select v-model="branchOffice" class="form-control form-select" :disabled="loading" id="branchOfficeReport">
              <option value="all" selected>Todas</option>
              <option v-for="bo in branchOffices" :value="bo.id" :key="bo.id">{{ bo.name }}</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-2">
            <label for="origins" class="form-label d-flex">No. econ&oacute;mico</label>
            <input id="origins" class="form-control" v-model="origin"/>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3 row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-3 row">
              <MazRadio
                  v-model="typeFilter"
                  name="isInterval"
                  value="isInterval"
              >
                Intervalo de fechas
              </MazRadio>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 mb-1">
              <VueDatePicker v-model="dateInit" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             :max-date="dateFinal??new Date()"
                             teleport-center
                             format="dd/MM/yyyy"
                             :disabled="loading || typeFilter!=='isInterval'"
                             placeholder="Fecha inicial"/>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 mb-1">
              <VueDatePicker v-if="dateInit"
                             v-model="dateFinal" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             :min-date="dateInit"
                             teleport-center
                             format="dd/MM/yyyy"
                             :disabled="loading || typeFilter!=='isInterval'"
                             placeholder="Fecha final"/>
              <VueDatePicker v-else
                             v-model="dateFinal" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             teleport-center
                             format="dd/MM/yyyy"
                             :disabled="loading || typeFilter!=='isInterval'"
                             placeholder="Fecha final"/>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3 row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-1 d-flex">
              <MazRadio
                  v-model="typeFilter"
                  name="isWeekly"
                  value="isWeekly"
              >
                Semanal
              </MazRadio>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-1">
              <input id="weeks" :disabled="loading || typeFilter!=='isWeekly'" placeholder="Semanas"
                     class="form-control"
                     v-model="weeks" required type="number" min="0"/>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3 row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-1 d-flex">
              <MazRadio
                  v-model="typeFilter"
                  name="isMonthly"
                  value="isMonthly"
              >
                Mensual
              </MazRadio>
            </div>
            <div class="col-sm-6 col-md-7 col-lg-8 mb-1">
              <select v-model="month" class="form-control form-select" :disabled="loading || typeFilter!=='isMonthly'">
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </div>
            <div class="col-sm-6 col-md-5 col-lg-4 mb-1">
              <input id="year" :disabled="loading || typeFilter!=='isMonthly'" placeholder="Año" class="form-control"
                     v-model="year"
                     required type="number" min="2000"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="()=>dialogSearch=false" :disabled="loading"
                    class="btn btn-secondary d-block w-100" type="button">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="()=>{
              dialogSearch=false;
              loadData(1);
            }" class="btn btn-danger d-block w-100" type="button"
                    :disabled="loading">Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top:99999px" v-show="export_excel">
    <table id="table_to_export" class="table text-nowrap align-middle mb-0">
      <thead>
      <tr>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Veh&iacute;culo
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          No. Econ&oacute;mico
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Color
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Categor&iacute;a
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          No. de serie
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Placa
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Sucursal
        </th>
        <th scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Monto de daños
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in data" :key="item.id">
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.vehiculo.modelo.name }} - {{ item.vehiculo.modelo.marca.name }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.vehiculo.noEconomico }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.vehiculo.color }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.vehiculo.categoria.name }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.vehiculo.noSerie }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.vehiculo.noPlaca }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.contratoSucursalRecogida.name }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ transfMount(totalDamages(item.damagescontractSet)) }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MazRadio from "maz-ui/components/MazRadio";
import Swal from "sweetalert2";
import {
  getBranchOffices,
  getContractsReport,
  getOriginsForReserveReport,
  getReportContractsWithDamage
} from "@/actions";
import {transfMount} from "@/util";
import moment from "moment/moment";
import * as XLSX from "xlsx";

export default {
  name: "ContractComponent",
  components: {MazRadio},
  data() {
    return {
      export_excel: false,
      loading: false,
      typeFilter: "isMonthly",

      page: 1,
      pages: 1,
      pageSizes: 10,

      url: `${process.env.VUE_APP_URL_SERVICES}report/contracts`,

      branchOffices: [],
      branchOffice: "all",
      data: [],
      origins: [],
      states: [
        {
          title: 'Abierto',
          value: 'Contratado'
        },
        {
          title: 'Cerrado',
          value: 'Cerrado'
        },
        {
          title: 'Cancelado',
          value: 'Cancelado'
        },
        {
          title: 'Vencido',
          value: 'Vencido'
        }
      ],

      origin: "",
      state: "all",
      weeks: null,
      month: null,
      year: null,
      dateInit: null,
      date: null,
      dateFinal: null,
      dialogSearch: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark',
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  watch: {
    typeFilter(value) {
      switch (value) {
        case "isDate":
          this.weeks = null;
          this.year = null;
          this.month = null;
          this.dateInit = null;
          this.dateFinal = null;
        case "isInterval":
          this.weeks = null;
          this.month = null;
          this.year = null;
          this.date = null;
          break;
        case "isMonthly":
          this.weeks = null;
          this.dateInit = null;
          this.dateFinal = null;
          this.date = null;
          break;
        case "isWeekly":
          this.year = null;
          this.month = null;
          this.dateInit = null;
          this.dateFinal = null;
          this.date = null;
          break;
        default:
          this.weeks = null;
          this.year = null;
          this.month = null;
          this.dateInit = null;
          this.dateFinal = null;
          this.date = null;
          break;
      }
    },
    pageSizes(value) {
      this.loadData(1);
    }
  },
  computed: {
    urlDownload() {
      let urlFinal = `${this.url}?token=${sessionStorage.getItem('token')}&`;
      if (this.origin !== null && this.origin !== "all") {
        urlFinal = `${urlFinal}origin=${this.origin}&`;
      }
      if (this.state !== null && this.state !== "all") {
        urlFinal = `${urlFinal}state=${this.state}&`;
      }
      if (this.weeks !== null) {
        urlFinal = `${urlFinal}weeks=${this.weeks}&`;
      }
      if (this.year !== null) {
        urlFinal = `${urlFinal}year=${this.year}&`;
      }
      if (this.month !== null) {
        urlFinal = `${urlFinal}month=${this.month}&`;
      }
      if (this.dateInit !== null && this.dateInit !== "") {
        urlFinal = `${urlFinal}init=${this.dateInit ? this.dateInit.toISOString().substr(0, 10) : null}&`;
      }
      if (this.dateFinal !== null && this.dateFinal !== "") {
        urlFinal = `${urlFinal}final=${this.dateFinal ? this.dateFinal.toISOString().substr(0, 10) : null}&`;
      }
      if (this.branchOffice !== null && this.branchOffice !== "" && this.branchOffice !== "all") {
        urlFinal = `${urlFinal}branchOffice=${this.branchOffice}&`;
      }
      return urlFinal.substring(0, urlFinal.length - 1)
    }
  },
  beforeMount() {
    const date = new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;
  },
  mounted() {
    this.loadOrigins();
    this.loadData(this.page);
    this.loadBranchOffices();
  },
  methods: {
    exportToExcel() {
      this.export_excel = true
      const table = document.getElementById('table_to_export');
      if (table) {
        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Listado de vehiculos con daños.xlsx");
        this.export_excel = false
      } else {
        console.error("No se encontró la tabla");
      }
    },
    totalDamages(damages) {
      let total = 0
      damages.forEach(damage => {
        total = total + parseFloat(damage.price)
      })
      return total
    },
    loadBranchOffices() {
      getBranchOffices().then(resp => {
        this.branchOffices = resp.data.data.searchBranchoffices.edges.map(e => e.node);
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      })
    },
    loadData(page) {
      this.loading = true;
      getReportContractsWithDamage({
        noEcon: this.origin === "" || !this.origin ? null : this.origin,
        state: "Cerrado",
        weeks: this.weeks,
        branchOffice: this.branchOffice === "all" || !this.branchOffice ? null : this.branchOffice,
        month: this.month ? parseInt(this.month) : null,
        year: this.year,
        dateInit: this.dateInit ? this.dateInit.toISOString().substr(0, 10) : null,
        date: this.date ? this.date.toISOString().substr(0, 10) : null,
        dateFinal: this.dateFinal ? this.dateFinal.toISOString().substr(0, 10) : null,
        pagination: {
          page: page,
          size: this.pageSizes
        }
      }).then(resp => {
        this.data = resp.data.data.reportContractsWithDamage.items;
        this.page = resp.data.data.reportContractsWithDamage.pagination.page;
        this.pages = resp.data.data.reportContractsWithDamage.pagination.pages;
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    loadOrigins() {
      getOriginsForReserveReport().then(resp => {
        this.origins = resp.data.data.procedenciaByName;
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      });
    },
    transfMount,
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm a");
    }
  }
}
</script>

<style scoped>
.icon-loading-custom {
  width: 22px !important;
  height: 22px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 60%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}
</style>