<template>
  <BreadCrumb PageTitle="Listado de emisores" Subtitle="Administración"></BreadCrumb>
  <ProdigiaIssuer :id="id"/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ProdigiaIssuer from "@/components/Nomencladores/ProdigiaIssuer.vue";

export default {
  name: "IssuerView",
  components: {ProdigiaIssuer, BreadCrumb},
  data(){
    return {
      id: ""
    }
  },
  created() {
    this.id = this.$route.params.id;
  }
}
</script>

<style scoped>

</style>