<template>
  <div class="card d-flex flex-row align-items-center div-custom-load bg-white">
    <div class="spinner-border text-dark spinner-custom" style="width: 5rem; height: 5rem; border-width: .5em;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingDatas"
}
</script>

<style scoped>
  .div-custom-load{
    padding: 5rem;
  }

  .spinner-custom{
    margin: 1rem auto;
  }
</style>