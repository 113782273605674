<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar temporada"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva temporada alta
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Fecha de inicio
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Fecha final
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary descripcion-column">
              {{ formatearFecha(item.firstDate) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearFecha(item.lastDate) }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar protección" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva temporada alta
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input autofocus v-model="name" :class="error_name?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de inicio</label>
            <input v-model="fecha_inicio" type="date"
                   :class="error_fecha_inicio?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de fin</label>
            <input v-model="fecha_fin" type="date" :class="error_fecha_fin?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar temporada alta
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input autofocus v-model="name" :class="error_name?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de inicio</label>
            <input v-model="fecha_inicio" type="date"
                   :class="error_fecha_inicio?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de fin</label>
            <input v-model="fecha_fin" type="date" :class="error_fecha_fin?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import {createHigthSeason, updateHigthSeason} from "@/actions";

const BUSCAR = gql`
  query HighSeasonByName($name:String!) {
    highSeasonByName(name_Icontains: $name) {
        edges {
            node {
                id
                name
                firstDate
                lastDate
            }
        }
    }
}`;
export default {
  name: "HighSeasonList",
  data() {
    return {
      items: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      Details_Modal: false,
      id: 0,
      operacion: "guardar",
      name: "",
      fecha_inicio: null,
      fecha_fin: null,

      error_name: "",
      error_fecha_inicio: null,
      error_fecha_fin: null,
      error_vacios: false,
      item: null,
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    name(val) {
      if (val !== '') {
        this.error_name = false
      }
    },
    fecha_inicio(val) {
      if (val !== null) {
        this.error_fecha_inicio = false
      }
    },
    fecha_fin(val) {
      if (val !== null) {
        this.error_fecha_fin = false
      }
    }
  },
  methods: {
    loadData() {
      this.$apollo.query({
        query: BUSCAR,
        variables: {
          name: this.search
        },
        fetchPolicy: "network-only"
      }).then(resp => {
        this.items = resp.data.highSeasonByName.edges.map(value => {
          return value.node;
        });
      })
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.name = item.name
      this.fecha_inicio = item.firstDate
      this.fecha_fin = item.lastDate
      this.id = item.id
      this.Actualizar_Modal = true
    },
    Editar() {
      let validacion = true
      if (this.name === '') {
        this.error_name = true
        validacion = false
      }
      if (this.fecha_inicio === null) {
        this.error_fecha_inicio = true
        validacion = false
      }
      if (this.fecha_fin === null) {
        this.error_fecha_fin = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        updateHigthSeason({
          name: this.name,
          firstDate: this.fecha_inicio,
          lastDate: this.fecha_fin,
          id: this.id
        }).then(response => {
          if (response.data.data.updateHighSeason.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.updateHighSeason.error
            });
            this.Close_update()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.Close_update()
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    Close_nuevo() {
      this.loadData()
      this.id = 0
      this.name = ""
      this.codigo = ""
      this.tipo_descuento = ""
      this.porciento = 0
      this.monto = 0
      this.fecha_inicio = null
      this.fecha_fin = null
      this.descripcion = ""
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.loadData()
      this.id = 0
      this.name = ""
      this.codigo = ""
      this.tipo_descuento = ""
      this.porciento = 0
      this.monto = 0
      this.fecha_inicio = null
      this.fecha_fin = null
      this.descripcion = ""
      this.Actualizar_Modal = false
    },
    Crear() {
      let validacion = true
      if (this.name === '') {
        this.error_name = true
        validacion = false
      }
      if (this.fecha_inicio === null) {
        this.error_fecha_inicio = true
        validacion = false
      }
      if (this.fecha_fin === null) {
        this.error_fecha_fin = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        createHigthSeason({
          name: this.name,
          firstDate: this.fecha_inicio,
          lastDate: this.fecha_fin,
        }).then(response => {
          if (response.data.data.setHighSeason.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.setHighSeason.error
            });
            this.Close_nuevo()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.Close_nuevo()
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    formatearFecha(date) {
      moment.locale("es");
      return moment(date).format("DD/MM/YYYY");
    }
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.descripcion-column {
  max-width: 200px; /* Ancho máximo para la columna */
  white-space: nowrap; /* Evita que el texto se desborde */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto recortado */
}

</style>
