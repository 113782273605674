<template>
  <BreadCrumb PageTitle="Listado de puntos por clientes" Subtitle="Administración"></BreadCrumb>
  <PuntosClienteList></PuntosClienteList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import PuntosClienteList from "@/components/Clientes/PuntosClienteList.vue";

export default {
  name: "Marcas",
  components: {BreadCrumb, PuntosClienteList}
}
</script>

<style scoped>

</style>
